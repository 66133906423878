import styled from "@emotion/styled"
import { ChevronIcon } from "components/icons"

interface ISelectProps {
	purpleBorder?: boolean
}

export const Select = styled.select<ISelectProps>`
	line-height: 5.6rem;
	height: 5.6rem;
	border: ${({ purpleBorder }) => `1px solid ${purpleBorder ? "rgba(53, 18, 112, 0.2)" : "#eaebea"}`};
	padding: 0 25px;
	font-size: 1.6rem;
	background: #fff;
	-webkit-appearance: none;
	width: 100%;
	max-width: 100%;
	text-overflow: ellipsis;
	border-radius: 4px;
	&:invalid,
	&[data-invalid="true"] {
		border-color: var(--error-color);
		outline: none;
		box-shadow: none;
	}
	&:focus {
		border-color: ${({ purpleBorder }) => `${purpleBorder ? "rgba(53, 18, 112, 0.6)" : "#979797"}`};
	}
`

export const SelectContainer = styled.div`
	position: relative;
	width: 100%;
	&:after {
		content: "";
		position: absolute;
		right: 18px;
		top: 50%;
		width: 12px;
		height: 6px;
		mask-image: url("${ChevronIcon}");
		mask-size: cover;
		mask-position: center;
		background-color: var(--main-color);
		transform: translateY(-50%);
	}
`
