import styled from "@emotion/styled"

const ErrorLabel = styled.label`
    line-height: 2.4rem;
    font-size: 1.2rem;
    color: var( --error-color );
    margin: 0 0 3px;
`

export default ErrorLabel
