export const getCookieObject = () => {
	return document.cookie.split( '; ' )
		.map( x => x.split( '=' ) )
		.reduce( ( acc, [ key, value ] ) => {
			acc[ key ] = value
			return acc
		}, {} )
}

export const getCookie = ( key: string ) =>
	getCookieObject()[ key ]