/* eslint-disable no-nested-ternary */
import React from "react"
import { css } from "@emotion/react"
import Media from "components/Media"
import { getColorScheme, getWhiteText, theme as themeCommon } from "common/colorScheme"
import styled from "@emotion/styled"
import BackgroundAnimation from "components/BackgroundAnimation"
import Container from "components/container"
import { P, SpanH3 } from "components/headings"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import { isIOS } from "components/Form/utils"
import { ISectionProps, MediaSize } from "../section.model"
import { elementData } from "../utils"
import ControlsOverlay from "./ControlsOverlay"
import Button from "../../button"

interface IVideoWrapperProps {
	video?: boolean
}

const sectionStyles = css`
	position: relative;
`

const MediaSectionStyles = styled.div`
	padding: 0;
	text-align: center;

	span {
		display: block;
		padding-bottom: ${themeCommon.spacing(4)};
	}
`

const mediaWrapper = css`
	display: flex;
	justify-content: center;
	max-width: 1008px;
	width: 100%;
`

const Wrapper = styled.div`
	position: relative;
	min-width: fit-content;
	height: 100%;
	width: 100%;
`

const VideoWrapper = styled.div<IVideoWrapperProps>`
	position: relative;
	${({ video }) =>
		video
			? `
		width: 100%;
		padding-bottom: 56.25%;
		height: 0;
		`
			: `padding: 0;
		display: flex;
		justify-content: center;
		
		${mediaBreakpoint(BreakPoints.MD)} {
			padding: ${themeCommon.spacing(8, 0)};
		}
		`}

	video, iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 8px;
		box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.2);
	}

	.gatsby-image-wrapper {
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0px 7px 98.77px 0 rgba(0, 0, 0, 0.25);

		${mediaBreakpoint(BreakPoints.LG)} {
			border-radius: 20px;
		}
	}
`

const ButtonsContainer = styled.div`
	display: flex;
	margin-top: ${themeCommon.spacing(6)};
`

const titleStyles = css`
	text-align: left;

	${mediaBreakpoint(BreakPoints.MD)} {
		text-align: center;
	}
`

const descriptionStyles = css`
	max-width: 1008px;
	margin-top: -20px;
	margin-bottom: 20px;
	text-align: left;

	a {
		text-decoration: none;
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		text-align: center;
	}
`

const getMediaSize = (size: MediaSize) => {
	switch (size) {
	case MediaSize.Large:
		return css`
				margin: 0 ${themeCommon.spacing(9)};
			`
	case MediaSize.Full:
		return css`
				${mediaBreakpoint(BreakPoints.SM)} {
					margin: -60px 0;
				}
			`
	default:
		return css``
	}
}

const MediaSection = ({
	media,
	embedYoutubeId,
	colorScheme,
	backgroundGradientsTopLeft,
	backgroundGradientsTopRight,
	backgroundGradientsBottomLeft,
	backgroundGradientsBottomRight,
	mediaSize,
	title,
	description,
	theme,
	customStyle,
	videoAutoplay,
	videoShareUrl,
	playVideoWhenInView,
	showVideoControls,
	videoPosterImage,
	loop,
	muted,
	innerHTML,
	buttons,
}: ISectionProps) => {
	const isVideo = media?.file.contentType.includes("video")
	const isEmbed = embedYoutubeId
	const isWhiteText = theme === "white-text"
	const isBackgroundGradient =
		backgroundGradientsTopLeft ||
		backgroundGradientsTopRight ||
		backgroundGradientsBottomLeft ||
		backgroundGradientsBottomRight

	const videoRef = React.useRef(null)
	const deviceIsIOS = isIOS()
	const isVideoMuted = () => {
		if (deviceIsIOS) return false
		return videoAutoplay || muted || playVideoWhenInView
	}

	const renderMedia = React.useMemo(
		() => (
			<Wrapper data-element={elementData.cardContainer}>
				{isVideo && showVideoControls && media && (
					<ControlsOverlay videoRef={videoRef} playWhenInView={playVideoWhenInView} videoShareUrl={videoShareUrl} />
				)}
				<VideoWrapper video={isVideo || isEmbed} data-element="video-wrapper">
					{media ? (
						<Media
							loop={loop}
							autoPlay={videoAutoplay}
							muted={isVideoMuted()}
							hideControls
							videoRef={videoRef}
							customStyle={{ width: "100%" }}
							poster={videoPosterImage?.file.url || ""}
							{...media}
						/>
					) : embedYoutubeId ? (
						<iframe
							style={{
								zIndex: 5,
							}}
							src={`https://www.youtube.com/embed/${embedYoutubeId}`}
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					) : null}
				</VideoWrapper>
				{isBackgroundGradient && (
					<BackgroundAnimation
						backgroundGradientsTopLeft={backgroundGradientsTopLeft}
						backgroundGradientsTopRight={backgroundGradientsTopRight}
						backgroundGradientsBottomLeft={backgroundGradientsBottomLeft}
						backgroundGradientsBottomRight={backgroundGradientsBottomRight}
					/>
				)}
			</Wrapper>
		),
		[media?.file, embedYoutubeId]
	)

	return media || embedYoutubeId ? (
		<section css={[customStyle, sectionStyles, getWhiteText(isWhiteText)]}>
			<MediaSectionStyles css={getColorScheme(colorScheme)} data-element={elementData.container}>
				<Container
					data-element={elementData.cover}
					customStyles={css`
						display: flex;
						flex-direction: column;
						align-items: center;
					`}
				>
					{title && (
						<div css={titleStyles}>
							<SpanH3>{title}</SpanH3>
						</div>
					)}
					{description && (
						<div css={descriptionStyles}>
							<P>{description}</P>
						</div>
					)}
					{innerHTML && !description && (
						<div css={descriptionStyles}>
							<P dangerouslySetInnerHTML={{ __html: innerHTML }} />
						</div>
					)}
					<div css={[getMediaSize(mediaSize), mediaWrapper]}>{renderMedia}</div>
					{buttons?.length > 0 && (
						<ButtonsContainer>
							{buttons.map(button => {
								const { id } = button
								return <Button key={id} {...button} />
							})}
						</ButtonsContainer>
					)}
				</Container>
			</MediaSectionStyles>
		</section>
	) : null
}

export default MediaSection
