import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Layout from "components/layout"
import Container from "components/container"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import SEO from "components/SEO"
import ModuleRender from "components/ModuleRender"
import { HeaderConfig } from "components/Header"
import { FooterConfig } from "components/Footer"
import { getCustomStyle } from "components/sections/utils"
import ArticlesGrid from "components/ArticlesGrid"

const query = graphql`
	query {
		contentfulPage(slug: { eq: "articles" }) {
			hideFooterDemo
			headerVariant
			customSpacing {
				internal {
					content
				}
			}
			seo {
				...SEO
			}
			sections {
				... on ContentfulAlternatingCards {
					...AlternatingCards
				}
				... on ContentfulBrandImagesSection {
					...BrandImages
				}
				... on ContentfulBrandImagesWithReviews {
					...BrandImagesWithReviews
				}
				... on ContentfulLandingSections {
					...Section
				}
				... on ContentfulReferencesSection {
					...ReferencesSection
				}
				... on ContentfulFeaturedSection {
					...Featured
				}
			}
		}
		contentfulForm(formId: { eq: "newsletter" }) {
			...Form
		}
		allContentfulBlogPost(filter: { node_locale: { eq: "en-US" } }, sort: { order: DESC, fields: publishDate }) {
			edges {
				node {
					id
					slug
					title
					shortDescription
					publishDate
					heroImage {
						...Media450
					}
					tags
					authorRef {
						firstName
						lastName
						slug
						title
						picture {
							...MediaOriginal
						}
					}
				}
			}
		}
	}
`

export const ArticlesContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
		margin: -12px;
	}
`

const Cover = styled.div`
	padding: 80px 0;
`

export const BottomContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 55px 0;
	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
`

export const Header = styled.div`
	display: flex;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		justify-content: space-between;
		flex-direction: row;
	}
`

export const HeaderLeft = styled.div``

export const HeaderRight = styled.div`
	width: 100%;
	max-width: 380px;
`

export const SearchInputWrapper = styled.div`
	position: relative;

	img {
		position: absolute;
		top: 50%;
		right: 17px;
		transform: translateY(-50%);
	}
`

export const SearchInput = styled.input`
	display: block;
	width: 100%;
	border: 1px solid rgba(51, 14, 112, 0.1);
	border-radius: 5px;
	font-size: 1.3rem;
	color: var(--main-color);
	line-height: 2em;
	font-weight: 800;
	padding: 10px 58px 10px 18px;
	margin-bottom: 4rem;
	text-transform: uppercase;
	back &:focus {
		border-color: var(--main-color);
	}
	&::placeholder {
		color: var(--main-color);
		opacity: 1;
	}
`

export const ArticlesWrapper = styled.div`
	width: 100%;
`

export const NoResultsContainer = styled.div`
	margin: 6.4rem auto;
	text-align: center;
	img {
		width: 250px;
	}
`

export const filterStyles = css`
	flex-shrink: 0;
`

const Articles = () => {
	const { contentfulPage, brandTrackingGuide, allContentfulBlogPost } = useStaticQuery(query)
	const { sections, seo, headerVariant, customSpacing, footerVariant } = contentfulPage

	const headerConfig: HeaderConfig = React.useMemo(() => {
		return {
			variant: headerVariant,
		}
	}, [headerVariant])

	const footerConfig: FooterConfig = React.useMemo(() => {
		return {
			variant: footerVariant,
			hideDemo: false,
			hideNewsletter: false

		}
	}, [footerVariant])

	const heading = "Brand Tracking Articles"
	const subHeading = "Read more about the world of brand."

	return (
		<div css={getCustomStyle(customSpacing)}>
			<SEO {...seo} />
			<Layout headerConfig={headerConfig} footerConfig={footerConfig}>
				<ModuleRender sections={sections} />
				<Cover>
					<Container>
						<ArticlesGrid
							heading={heading}
							subHeading={subHeading}
							allContentfulBlogPost={allContentfulBlogPost}
							contentfulForm={brandTrackingGuide}
						/>
					</Container>
				</Cover>
			</Layout>
		</div>
	)
}

export default React.memo(Articles)
