/* eslint-disable indent */
import React, { useRef, useState, useEffect } from "react"
import { css } from "@emotion/react"
import useIntersectionObserver from "utils/useIntersectionObserver"

interface IScrollAnimationProps {
	type?: EntryAnimations
	alternate?: boolean
	addClass?: boolean
	children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

export type EntryAnimations = "slideFromSideWithFade" | "slideFromBottomWithFade"

const scrollAnimation = css`
	transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
	width: inherit;
`

const fadeFromLeftOpacityIn = css`
	opacity: 0;
	transform: translateX(-5%);

	&.animate {
		opacity: 1;
		transform: translateX(0%);
	}
`

const fadeFromRightOpacityIn = css`
	opacity: 0;
	transform: translateX(5%);

	&.animate {
		opacity: 1;
		transform: translateX(0%);
	}
`

const fadeFromBottomOpacityIn = css`
	opacity: 0;
	transform: translateY(5%);

	&.animate {
		opacity: 1;
		transform: translateY(0%);
	}
`

const ScrollAnimation: React.FC<IScrollAnimationProps> = ({ type, alternate, addClass, children }) => {
	const [shouldAnimate, setShouldAnimate] = useState(false)

	const containerRef = useRef(null)
	const entry = useIntersectionObserver(containerRef, { threshold: 0.5 })
	const visible = !!entry?.isIntersecting

	useEffect(() => {
		if (visible && !shouldAnimate) setShouldAnimate(true)
	}, [visible])

	const getAnim = () => {
		if (alternate) {
			switch (type) {
				case "slideFromSideWithFade":
					return fadeFromLeftOpacityIn
				case "slideFromBottomWithFade":
					return fadeFromBottomOpacityIn
				default:
					return null
			}
		} else {
			switch (type) {
				case "slideFromSideWithFade":
					return fadeFromRightOpacityIn
				case "slideFromBottomWithFade":
					return fadeFromBottomOpacityIn

				default:
					return null
			}
		}
	}

	return addClass ? (
		React.cloneElement(children, {
			className: `${children.props.className} ${shouldAnimate && "animate"}`,
			ref: containerRef,
		})
	) : (
		<div css={[scrollAnimation, getAnim()]} className={shouldAnimate ? "animate" : ""} ref={containerRef}>
			{children}
		</div>
	)
}

export default ScrollAnimation
