export const isItemActive = ({
	parentIndex,
	subIndex,
	activeParentIndex,
	activeSubIndex,
}) => {
	return (
		(parentIndex === activeParentIndex && subIndex === null) ||
    (parentIndex === activeParentIndex && subIndex === activeSubIndex)
	)
}

export const isPanelActive = ({
	parentIndex,
	subIndex,
	activeParentIndex,
	activeSubIndex,
	subItems,
}) => {
	return (
		(parentIndex === activeParentIndex && subIndex === activeSubIndex) ||
    (parentIndex === activeParentIndex && !subItems && subIndex === null)
	)
}

export const getActiveClass = (active) => (active ? 'active' : '')
