import React from "react"
import Media from "components/Media"
import { ButtonBase } from "components/button"
import { css } from "@emotion/react"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import { theme } from "common/colorScheme"
import { ArticleTitle, ArticleDescription } from "./index"

const additionalStyles = css`
	.gatsby-image-wrapper {
		margin-bottom: ${theme.spacing(2)};
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: calc(100% / 3 - 24px);
		max-width: calc(100% / 3 - 24px);
		margin: 12px 12px 60px;
	}
`

const articleContainerStyles = css`
	color: var(--text-color);
	text-decoration: none;
`

const HighlightCard = ({ title, link, heroImage, description }) => {
	const highlightLink = link ? link.url : "/"
	return (
		<a css={[articleContainerStyles, additionalStyles]} href={highlightLink || "/"}>
			<div>
				<Media alt={title} {...heroImage} />
				<ArticleTitle>{title}</ArticleTitle>
				<ArticleDescription>{description}</ArticleDescription>
			</div>
			<div>
				<ButtonBase data-variant="hollow" type="button">
					Learn more
				</ButtonBase>
			</div>
		</a>
	)
}

export default HighlightCard
