import styled, { StyledComponent } from '@emotion/styled'

export type AxisAlignment =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'stretch'
  | 'baseline';

interface AxisProps {
  mainAxis?: AxisAlignment;
  crossAxis?: AxisAlignment;
}

export const Row: StyledComponent<
  {},
  {},
  { mainAxis?: AxisAlignment; crossAxis?: AxisAlignment }
> = styled.div`
  display: flex;
  justify-content: ${(props: AxisProps) =>
		props.mainAxis ? props.mainAxis : 'flex-start'};
  align-items: ${(props: AxisProps) =>
		props.crossAxis ? props.crossAxis : 'flex-start'};
`

export const Column: StyledComponent<
  {},
  {},
  { mainAxis?: AxisAlignment; crossAxis?: AxisAlignment }
> = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: AxisProps) =>
		props.mainAxis ? props.mainAxis : 'flex-start'};
  align-items: ${(props: AxisProps) =>
		props.crossAxis ? props.crossAxis : 'flex-start'};
`

export const SizedBox: StyledComponent<
  {},
  {},
  { height?: number; width?: number }
> = styled.div`
  width: ${(props: any) => (props.width ? `${props.width}px` : 'auto')};
  height: ${(props: any) => (props.height ? `${props.height}px` : 'auto')};
`

export const Spacer: StyledComponent<{}, {}, { flex?: number }> = styled.div`
  flex: ${(props: any) => (props.flex ? `${props.flex}` : '1')};
`
