import React from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import Container from "components/container"
import { getColorScheme, getWhiteText, IColorScheme, theme as themeCommon } from "common/colorScheme"
import { IFeatured } from "components/Header/types"
import Media from "components/Media"
import { css } from "@emotion/react"
import Link from "components/Link"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"

interface FeaturedProps {
	__typename: string
	subTitle: string
	featured: IFeatured
	featuredDescription?: {
		featuredDescription: string
	}
	referencesSubTitle?: string
	references?: IFeatured[]
	colorScheme?: IColorScheme
	theme?: string
}

const Wrapper = styled.section`
	width: 100%;
`

const containerStyles = css`
	padding-top: 30px !important;
	padding-bottom: 60px !important;
`

const FeaturedSection = styled.div`
	display: flex;
	flex-direction: column-reverse;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
`

const TextWrapper = styled.div`
	width: 100%;

	${mediaBreakpoint(BreakPoints.MD)} {
		width: 50%;
		max-width: 500px;
	}
`

const SubTitle = styled.h2`
	font-weight: 800;
	text-transform: uppercase;
	font-size: 1.2em;
	color: ${themeCommon.color.cyan} !important;
	margin: 0;
	margin-bottom: ${themeCommon.spacing(2)};
`

const Title = styled.h1`
	font-weight: 800;
	font-size: 2em;
	line-height: 1.45em;
	margin: 0;
	margin-bottom: ${themeCommon.spacing(2)};

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 3.2em;
		line-height: 1.2em;
	}
`

const Description = styled.p`
	font-size: 1.6em;
	line-height: 1.6em;
	margin: 0;

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 1.9em;
		line-height: 1.4em;
	}
`

const ImageWrapper = styled.div`
	width: 100%;
	display: flex;

	margin-bottom: ${themeCommon.spacing(3)};

	.gatsby-image-wrapper {
		width: initial;
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.15);
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		max-width: 50%;
		justify-content: flex-end;
		margin-bottom: initial;
	}
`

const ReferencesSubTitle = styled(SubTitle)`
	margin-bottom: ${themeCommon.spacing(3)};
`

const ReferencesSection = styled.div`
	margin-top: ${themeCommon.spacing(6)};

	${mediaBreakpoint(BreakPoints.MD)} {
		margin-top: ${themeCommon.spacing(3)};
	}
`

const ReferencesContainer = styled.div`
	display: flex;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
`

const Reference = styled.div`
	width: 100%;
	&:not(:last-of-type) {
		margin-bottom: ${themeCommon.spacing(5)};
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		width: calc(100% / 4 - calc(24px * 3 / 4));
		max-width: 304px;

		&:not(:last-of-type) {
			margin-right: 24px;
			margin-bottom: 0;
		}
	}
`

const ReferenceTitle = styled.h2`
	font-size: 2em;
	font-weight: 800;
	line-height: 1.3em;
	margin: 0;
`

const ReferenceImageWrapper = styled.div`
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: ${themeCommon.spacing(3)};
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
`

const Featured: React.FC<FeaturedProps> = ({
	subTitle,
	featured,
	referencesSubTitle,
	references,
	colorScheme,
	theme,
}) => {
	const whiteText = theme === "white-text"
	const description = featured.shortDescription

	return (
		<Wrapper css={[getColorScheme(colorScheme), getWhiteText(whiteText)]}>
			<Container css={containerStyles}>
				<FeaturedSection data-element="featured-section">
					<TextWrapper>
						<SubTitle>{subTitle}</SubTitle>
						<Link to={`/post/${featured.slug}`}>
							<Title>{featured.title}</Title>
						</Link>
						<Link to={`/post/${featured.slug}`}>
							<Description as="p">{description}</Description>
						</Link>
					</TextWrapper>
					<ImageWrapper>
						<Link to={`/post/${featured.slug}`}>
							<Media {...featured.heroImage} />
						</Link>
					</ImageWrapper>
				</FeaturedSection>
				<ReferencesSection>
					{referencesSubTitle && <ReferencesSubTitle>{referencesSubTitle}</ReferencesSubTitle>}
					<ReferencesContainer>
						{references &&
							references.map(reference => (
								<Reference key={reference.id}>
									<ReferenceImageWrapper>
										<Link to={`/post/${reference.slug}`}>
											<Media {...reference.heroImage} />
										</Link>
									</ReferenceImageWrapper>
									<Link to={`/post/${reference.slug}`}>
										<ReferenceTitle>{reference.title}</ReferenceTitle>
									</Link>
								</Reference>
							))}
					</ReferencesContainer>
				</ReferencesSection>
			</Container>
		</Wrapper>
	)
}

export const query = graphql`
	fragment Featured on ContentfulFeaturedSection {
		__typename
		id
		subTitle
		featured {
			id
			slug
			title
			shortDescription
			publishDate
			heroImage {
				...Media600
			}
			tags
		}
		referencesSubTitle
		references {
			id
			slug
			title
			shortDescription
			publishDate
			heroImage {
				...Media450
			}
			tags
		}
		colorScheme {
			...ColorScheme
		}
		theme
	}
`

export default Featured
