/* eslint-disable react/require-default-props */
import * as React from "react"
import Container from "components/container"
import styled from "@emotion/styled"
import { getWhiteText, theme as themeCommon } from "common/colorScheme"
import { css } from "@emotion/react"
import { graphql } from "gatsby"
import { useSectionTheme } from "components/sections/SectionThemeContext"
import Card, {CardProps} from "components/sections/Card/Card"
import { getSizeStyles, useTitleSize } from "components/sections/TitleSizeContext"
import { elementData, formatMultilineText, getCustomStyle } from "components/sections/utils"
import { ITitleLevel, SectionLayoutAlign , ITitleSizeValue} from "components/sections/section.model"
import { IJsonContentfulField } from "common/types"
import Media from "components/Media"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { H2, P } from "../headings"

interface IEmbedSpotifySectionProps {
    imageTop: any
    longTitle: {
        longTitle : string
      }
    card?: CardProps
    spotifyId?: string
    description?: {
		description: string
	}
    customSpacing?: IJsonContentfulField
    titleLevel?: ITitleLevel
    layoutAlignment?: SectionLayoutAlign | null
    cardsTitle?: string
    titleSize: ITitleSizeValue | null
}

export const hideOnMobile = css`
	display: none;

	${mediaBreakpoint(BreakPoints.LG)} {
		display: block;
	}
`

const containerStyles = css`
	display: flex;
	flex-direction: column;
	padding-top: ${themeCommon.spacing(6)};
	padding-bottom: ${themeCommon.spacing(6)};

	${mediaBreakpoint(BreakPoints.MD)} {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-top: 0;
		padding-bottom: 0;
	}
`

const SubTitle = styled.div`
	width:18rem
`

const Cover = styled.div`
	display: flex;
	content-visibility: initial;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: ${themeCommon.spacing(7.5, 0)};
		align-items: center;
	}
`

const TextContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
    ${P} {
        font-size: 3.2rem;
        font-weight: bold;
    }
	${H2} {
		font-size: 4.8rem;	
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		width: 100%;
		display: flex;	
		flex-direction: row;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${H2} {
			white-space: pre;
		}
	}
`

const MediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	align-self: stretch;
	align-items: center;
	p{
		padding-top:2rem;
		width: 100%;

	}
	${mediaBreakpoint(BreakPoints.MD)} {
		display: flex;
		flex-direction: row;
		align-items: unset;
		width: 100%;
		margin-left: 60px;
		p{
			padding-top: 2rem;
			width: 60%;
	
		}
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		margin: 0;
	}
`
const PodCastContainer = styled.div `
	width: 100%;
	padding-top: 8rem;
	iframe {
		border-radius: 10px;
		box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
		width: 100%;
		z-index: 5;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		width: 60%;
		iframe {
			border-radius: 10px;
			box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
			width: 100%;
			z-index: 5;
		}
	}
`
const FeaturedCard = styled.div `
	width: 100%;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.LG)} {
		width: 40%;
	}
	
`
const alignContentFirsttStyles = css`
	${MediaContainer} {
		margin-top: ${themeCommon.spacing(6.5)};

		${mediaBreakpoint(BreakPoints.LG)} {
			margin-top: ${themeCommon.spacing(4)};
		}
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${TextContainer} {
			justify-content: space-between;
		}
		${MediaContainer} {
			margin-top: 0;
			justify-content: space-between;
		}
	}
`

const alignMediaFirstStyles = css`
	${MediaContainer} {
		margin-top: ${themeCommon.spacing(4)};
		margin-bottom: ${themeCommon.spacing(2)};
		order: 1;
	}

	${TextContainer} {
		order: 2;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${MediaContainer} {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
`

const CardsTitle = styled.p `
    padding-top: 12rem;
    font-size: 3.2rem;
    font-weight: 900;

`

const layoutAlignStyles = {
	[SectionLayoutAlign.ContentFirst]: alignContentFirsttStyles,
	[SectionLayoutAlign.MediaFirst]: alignMediaFirstStyles,
}

const EmbedSpotifySection: React.FC<IEmbedSpotifySectionProps> = ({
	customSpacing,
	layoutAlignment,
	imageTop,
	spotifyId,
	card,
	description,
	titleLevel,
	cardsTitle,
	longTitle
}) => {
	const theme = useSectionTheme()
	const whiteText = theme === "white-text"
	const titleSize = useTitleSize()
	return (
		// eslint-disable-next-line react/no-unknown-property
		<section css={[getCustomStyle(customSpacing)]}>
			<Cover data-element={elementData.cover}>
				<Container css={[containerStyles, layoutAlignStyles[layoutAlignment]]} data-element={elementData.container}>
					<TextContainer data-element={elementData.textContainer} css={getWhiteText(whiteText)}>
						<FeaturedCard>
							{imageTop ? <SubTitle><Media {...imageTop}/></SubTitle> : null}
							<H2 css={getSizeStyles(titleSize)} as={titleLevel || "h2"} data-element="title-text">
								{longTitle?.longTitle ? longTitle.longTitle : null}
							</H2>
						</FeaturedCard>
						<PodCastContainer>
							{spotifyId ? (					
								<iframe 
									src={`https://open.spotify.com/embed/episode/${spotifyId}`}
									width="100%" 
									height="232" 
									frameBorder="0" 
									allowFullScreen 
									allow="autoplay; 
                            clipboard-write; 
                            encrypted-media; 
                            fullscreen; 
                            picture-in-picture"
									title= "latana Podcasts"/>						
							) : null}
						</PodCastContainer>
					</TextContainer>
					<MediaContainer data-element={elementData.bannerMediaContainer}>
						<FeaturedCard data-element="featured-card">
							{cardsTitle ? <CardsTitle>{cardsTitle}</CardsTitle> : null  }
							{card ? <Card {...card}/> : null}
						</FeaturedCard>
						{description && <P>{formatMultilineText(description.description)}</P>}
					</MediaContainer>
				</Container>
				
			</Cover>
		</section>

	)
}
export const query = graphql`
	fragment EmbedSpotifySection on ContentfulEmbedSpotifySection {
        __typename
		id
        imageTop {
            ...Media400
        }
        longTitle {
            longTitle
        }
        cardsTitle
        description {
            description
        }
        customSpacing {
        customInternal {
            content
        }
        }
        spotifyId
        card {
        ...Card
        }
        titleSize
		titleLevel
        layoutAlignment
	}
`

export default React.memo(EmbedSpotifySection)
