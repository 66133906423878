import styled, { StyledComponent } from '@emotion/styled'
import { ButtonHTMLAttributes } from 'react'
import { BreakPoints, mediaBreakpoint } from 'settings/breakpoints'
import { Row } from './layout'

export const OuterContainer = styled.section`
  margin-bottom: 40px;
  ${mediaBreakpoint(BreakPoints.SM)} {
    margin-bottom: 78px;
  }
`

export const ButtonRow = styled(Row)`
  gap: 16px 12px;
`

export const ButtonRowMobile = styled(Row)`
  gap: 16px 5px;
  flex-wrap: wrap;
`

export const IconRow = styled(Row)`
  gap: 6px 10px;
`

export const IconRowMobile = styled(Row)`
  gap: 6px 5px;
  flex-wrap: wrap;
`

export const Title = styled.h3`
  font-size: 2.4rem;
  line-height: 5rem;
  font-weight: 800;
  margin: 0;
`

// get color from theme
export const CTA = styled.span`
  ${mediaBreakpoint(BreakPoints.SM)} {
    font-size: 2rem;
  }
  font-size: 1.8rem;
  text-align: center;
  line-height: 3rem;
  font-weight: 800;
  color: #9450ff;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: #31135e;
  }
`

interface BrandButtonInteface extends ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean;
  enabled: boolean;
}

export const BrandButton: StyledComponent<
  {},
  {},
  BrandButtonInteface
> = styled.button`
  width: 79px;
  height: 32px;
  background-color: ${(props: BrandButtonInteface) =>
		props.active ? '#9450ff' : props.enabled ? '#9450FF33' : '#f1f1f1'};
  color: ${(props: BrandButtonInteface) =>
		props.active ? '#ffffff' : props.enabled ? '#9450FF' : '#bcbcbc'};
  border-radius: 19px;
  border: unset;
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 800;
  cursor: ${(props: BrandButtonInteface) =>
		props.enabled ? 'pointer' : 'auto'};
  :hover {
    background-color: ${(props) =>
		props.active ? '#9450ff' : props.enabled ? '#6420CF33' : '#f1f1f1'};
  }
`

export const CategoryName = styled.span`
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 800;
  letter-spacing: 0.05em;
  width: 80px;
  text-align: center;
`
