import { createContext, useContext } from "react"
import { css } from "@emotion/react"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { ITitleSizeValue } from "./section.model"

export const TitleSizeContext = createContext<ITitleSizeValue>("60/34")

export const useTitleSize = (): ITitleSizeValue | null => useContext(TitleSizeContext)

export const getSizeStyles = (size: ITitleSizeValue) => {
	switch (size) {
	case "20/16":
		return css`
				font-size: 1.6rem;
				line-height: 2.4rem;

				${mediaBreakpoint(BreakPoints.SM)} {
					font-size: 2rem;
					line-height: 2.8rem;
				}
			`
	case "36/24": {
		return css`
				font-size: 2.4rem;
				line-height: 3rem;

				${mediaBreakpoint(BreakPoints.SM)} {
					font-size: 3.6rem;
					line-height: 4.8rem;
				}
			`
	}

	case "28/24": {
		return css`
				font-size: 2.4rem;
				line-height: 2.8rem;

				${mediaBreakpoint(BreakPoints.SM)} {
					font-size: 2.8rem;
					line-height: 3.2rem;
				}
			`
	}
	case "32/28": {
		return css`
				font-size: 2.8rem;
				line-height: 3.2rem;

				${mediaBreakpoint(BreakPoints.SM)} {
					font-size: 3.2rem;
					line-height: 3.8rem;
				}
			`
	}
	case "40/30": {
		return css`
				font-size: 3rem;
				line-height: 4rem;

				${mediaBreakpoint(BreakPoints.SM)} {
					font-size: 4rem;
					line-height: 4.4rem;
				}
			`
	}
	case "42/32": {
		return css`
				font-size: 3.2rem;
				line-height: 4rem;

				${mediaBreakpoint(BreakPoints.SM)} {
					font-size: 4.2rem;
					line-height: 4.4rem;
				}
			`
	}
	case "48/30": {
		return css`
				font-size: 3rem;
				line-height: 3.6rem;

				${mediaBreakpoint(BreakPoints.SM)} {
					font-size: 4.8rem;
					line-height: 5.2rem;
				}
			`
	}
	case "60/34":
	default: {
		return css`
				font-size: 3.4rem;
				line-height: 3.9rem;

				${mediaBreakpoint(BreakPoints.SM)} {
					font-size: 6rem;
					line-height: 6.4rem;
				}
			`
	}
	}
}
