import React from "react"
import { css } from "@emotion/react"
import Container from "../container"
import Button, { ButtonBase } from "../button"
import { H2, P } from "../headings"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import Card, { Category } from "./Card/Card"
import Media from "../Media"
import { ISectionProps } from "./section.model"
import { getColorScheme,  getWhiteText } from "../../common/colorScheme"
import { useSectionTheme } from "./SectionThemeContext"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { elementData } from "./utils"

const textAndMediaContainerStyles = css`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    ${mediaBreakpoint(BreakPoints.SM)} {
        flex-direction: row;
        align-items: center;
    }
`

const containerStyles = css`
    padding-bottom: 56px;

    ${mediaBreakpoint(BreakPoints.SM)} {
        flex-direction: column;
        display: flex;
    }
`

const coverStyles = css`
    display: flex;
    justify-content: center;
    content-visibility: initial;
`

const bannerTextContainerStyles = css`
    flex-basis: 100%;
    margin: 0 0 64px;
    padding: 87px 0 0;
    align-self: flex-start;
    ${mediaBreakpoint(BreakPoints.SM)} {
        flex-basis: 38%;
        margin: 0;
        padding: 118px 0 0;
    }
`

const bannerMediaStyles = css`
    ${mediaBreakpoint(BreakPoints.SM)} {
        max-width: 50%;
    }
`

const buttonsContainerStyles = css`
    ${ButtonBase} {
        width: 100%;
        margin: 0 0 12px;
        ${mediaBreakpoint(BreakPoints.SM)} {
            width: initial;
            margin: 0;
            min-width: 134px;
        }
    }
`

const buttonStyles = css`
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    ${mediaBreakpoint(BreakPoints.SM)} {
        display: inline-block;
    }
`

const cardsContainerStyles = css``

const BannerLeft: React.FC<ISectionProps> = ({
	title,
	titleLevel,
	description,
	media = null,
	buttons,
	colorScheme,
	cards,
	children,
	customStyle,
	category
}) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()
	const whiteText = theme === "white-text"

	return (
		<section css={[coverStyles, getColorScheme(colorScheme), customStyle]} data-element={elementData.section}>
			<Container css={containerStyles} data-element={elementData.container}>
				<div css={[textAndMediaContainerStyles]} data-element={elementData.textAndMediaContainer}>
					<div css={[bannerTextContainerStyles, getWhiteText(whiteText)]} data-element={elementData.bannerTextContainer}>
						{category ? <Category data-element="card-category">{category.title}</Category> : null}
						<H2 css={getSizeStyles(titleSize)} as={titleLevel || "h2"}>
							{title}
						</H2>
						<P>{description}</P>
						<div css={buttonsContainerStyles}>
							{(buttons || []).map(button => {
								const { id } = button
								return (
									<div key={id} css={buttonStyles}>
										<Button {...button} />
									</div>
								)
							})}
						</div>
					</div>
					{media && (
						<div css={bannerMediaStyles}>
							<Media {...media} />
						</div>
					)}
				</div>
				<div css={cardsContainerStyles}>
					{(cards || []).map(card => (
						<Card key={card.id} {...card} />
					))}
				</div>
				{children}
			</Container>
		</section>
	)
}

export default React.memo(BannerLeft)