import React, { memo, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { richTextFromMarkdown } from '@contentful/rich-text-from-markdown'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { ISectionProps } from './section.model'
import { RichTextOptions, ImageToRichText } from '../../utils/richText'
import Container from '../container'

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  ${ mediaBreakpoint(BreakPoints.SM) } {
    flex-direction: row;
  }
`
const Content = styled.div`
  flex: 2;

  ${ mediaBreakpoint(BreakPoints.SM) } {
    margin-right: 10rem;
    p {
      font-size: 2rem;
    }
  }
`
const Aside = styled.aside`
  flex: 1;
`
const AsideHeader = styled.h4`
  font-size: 1.2rem;
  line-height: 2;
  font-weight: 800;
  color: #31135e;
  margin: 0;
  text-transform: uppercase;
`
const HR = styled.hr`
  background: black;
`

const File = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: #000;
    text-decoration: none;
    font-size: 2rem;
    line-height: 2.8rem;
  }
  a:hover {
    text-decoration: underline;
  }
  span {
    font-size: 1.6rem;
  }
`

const WithAside = ({additionalText, title, titleLevel, files}: ISectionProps) => {
	const [contentFromMarkdown, setContentFromMarkdown] = useState(null)

	useEffect(() => {
		if ( additionalText ) {
			richTextFromMarkdown( additionalText.additionalText, ImageToRichText )
				.then(
					richText => setContentFromMarkdown(
						documentToReactComponents( richText, RichTextOptions )
					)
				)
		}
	}, [ additionalText ] )

	const formattedDate = (date) => {
		return new Date( date ).toLocaleDateString( 'en-US', {
			month: 'long',
			day: 'numeric',
			year: 'numeric' 
		} )
	}

	return (
		<section>
			<StyledContainer>
				<Content>
					{contentFromMarkdown}
				</Content>
				<Aside>
					<AsideHeader as={titleLevel || 'h2'}>{title}</AsideHeader>
					<HR/>
					<div>
						{files && files.map(file => (
							<File>
								<a href={file.file.file.url} target="_blank" rel="noreferrer" download>{file.title}</a>
								<span>{file.author && `${file.author}, `}{formattedDate(file.releaseDate)}</span>
							</File>
						))}
					</div>
				</Aside>
			</StyledContainer>
		</section>
	)
}

export default memo(WithAside)