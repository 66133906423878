import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Container from 'components/container'
import { css } from '@emotion/react'
import Media, { MediaProps } from '../Media'
import { Row, Column, SizedBox, Spacer } from './layout'
import {
	BrandButton,
	ButtonRow,
	ButtonRowMobile,
	CategoryName,
	CTA,
	IconRow,
	IconRowMobile,
	OuterContainer,
	Title,
} from './components'
import { showOnMobile, hideOnMobile } from './styles'

export interface BrandCategorySectionProps {
  sectionTitle: string;
  id: string;
  cta: string;
  ctaTargetId: string;
  categories: {
    enabled: boolean;
    name: string;
    icons: MediaProps[];
  }[];
}

const BrandCategorySection: React.FC<BrandCategorySectionProps> = ({
	sectionTitle,
	cta,
	ctaTargetId,
	categories,
	id,
}) => {
	const [activeIndex, setActiveIndex] = useState(0)

	function getCategories(inputCategories, activeCategoryIndex) {
		const categoryElements = inputCategories.map(({ name, enabled }, index) => {
			const active = index === activeCategoryIndex
			return (
				<BrandButton
					key={name}
					onClick={() => {
						if (enabled) {
							setActiveIndex(index)
						}
					}}
					active={active}
					enabled={enabled}
				>
					{name}
				</BrandButton>
			)
		})
		return categoryElements
	}

	function getIcons(inputIcons) {
		const customStyle = css`
      width: 45px;
    `
		const iconElements = inputIcons.map((image) => (
			<Media {...image} customStyle={customStyle} />
		))

		return iconElements
	}

	function scrollToId(targetId) {
		const element = window.document.getElementById(targetId)
		if (element) {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest',
			})
		}
	}

	const activeCategory = categories[activeIndex]
	const { name: categoryName, icons: categoryIcons } = activeCategory

	return (
		<OuterContainer key={id}>
			<Container>
				{/* desktop version */}
				<Column crossAxis="stretch" css={hideOnMobile}>
					{/* header row */}
					<Row crossAxis="center">
						<Title>{sectionTitle}</Title>
					</Row>
					<SizedBox height={8} />
					{/* categories row */}
					<ButtonRow>
						{getCategories(categories, activeIndex)}
						<SizedBox width={118} />
						<Spacer />
						<CTA onClick={() => scrollToId(ctaTargetId)}>{cta}</CTA>
					</ButtonRow>
					<SizedBox height={40} />
					{/* content row */}
					<IconRow crossAxis="center">
						<CategoryName>{categoryName}</CategoryName>
						<SizedBox width={3} />
						{getIcons(categoryIcons)}
					</IconRow>
				</Column>
				{/* mobile version */}
				<Column crossAxis="stretch" css={showOnMobile}>
					{/* header row */}
					<Row crossAxis="center">
						<Title>{sectionTitle}</Title>
					</Row>
					<SizedBox height={8} />
					{/* categories row */}
					<ButtonRowMobile>
						{getCategories(categories, activeIndex)}
					</ButtonRowMobile>
					<SizedBox height={20} />
					{/* content row */}
					<Row mainAxis="center">
						<CategoryName>{categoryName}</CategoryName>
					</Row>
					<SizedBox height={20} />
					<IconRowMobile>{getIcons(categoryIcons)}</IconRowMobile>
					<SizedBox height={40} />
					<Row mainAxis="center">
						<CTA onClick={() => scrollToId(ctaTargetId)}>{cta}</CTA>
					</Row>
				</Column>
			</Container>
		</OuterContainer>
	)
}

export const query = graphql`
  fragment BrandCategorySection on ContentfulBrandCategorySection {
    __typename
    id
    sectionTitle: title
    cta
    ctaTargetId
    categories {
      enabled
      name
      icons {
        ...MediaOriginal
      }
    }
  }
`

export default React.memo(BrandCategorySection)
