import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { CodeIcon, DownloadIcon } from "components/icons"
import Media from "components/Media"
import * as React from "react"

const FadeIn = keyframes`
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`

const I = styled.i<{ icon: string }>`
	display: block;
	width: 20px;
	height: 20px;
	background-image: url(${props => props.icon});
	background-position: center center;
	background-size: 20px 20px;
	background-repeat: no-repeat;
`

const Notification = styled.div`
	position: absolute;
	width: auto !important;
	padding: 0.8rem 1.2rem;
	font-size: 1.6rem;
	top: 0;
	color: white;
	background: #000;
	border-radius: 3px;
	z-index: 1;
	animation: 0.2s ${FadeIn} ease-in-out forwards;
	&.fading {
		animation-direction: reverse;
	}
`

const ClipBoardInput = styled.input`
	opacity: 0;
	max-width: 0;
	pointer-events: none;
`

const Fab = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0.8rem;
    width: 42px;
    height: 42px;
    background: #f9f9fb;
    border: none;
    border-radius: 50%;
    transition: background .2s ease-out:
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    cursor: pointer;
    &:hover {
        transition: background .2s ease-in:
        color: #fff;
        background: var( --main-color );
        ${I} {
            filter: brightness(0) invert(1);
        }
    }
`

const ActionsContainer = styled.div`
	transition: 0.2s transform ease-in-out;
	text-align: right;
	position: absolute;
	z-index: 1;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 6.4rem auto;
	width: 100%;
	position: relative;
	overflow: hidden;
	img {
		margin: 0;
	}
	> div {
		width: 100%;
	}
`

interface Props {
	id: string;
	file: {
		contentType: string;
		url: string;
		fileName: string;
		details: {
			image: {
				width: number;
				height: number;
			} | null;
		};
	} | null;
	fluid: any | null;
	imgStyle?: any;
	description?: string;
	alt?: string;
}

const Infographic: React.FC<Props> = props => {
	const [notification, setNotification] = React.useState<string>()
	const [HTMLText, setHTMLText] = React.useState<string>()
	const {
		file: { fileName, url },
		description,
		alt,
	} = props
	const title = description || alt

	React.useEffect(() => {
		setHTMLText(
			// eslint-disable-next-line no-restricted-globals
			`<img width="100%" src="${url}" alt="${title}" title="${title}" /><p>Embedded from <a target="_blank" href="${location.href}">Latana</a></p>`
		)
	}, [])

	const inputRef = React.useRef<HTMLInputElement>()
	const notificationRef = React.useRef<HTMLInputElement>()

	const handleCodeClick = React.useCallback(() => {
		const input = inputRef.current

		if (input) {
			input.focus()
			input.select()
			document.execCommand("copy")
			setNotification("Copied to clipboard")

			setTimeout(() => {
				setNotification(undefined)
			}, 3000)
		}
	}, [])

	return (
		<Container>
			<ActionsContainer>
				<ClipBoardInput ref={inputRef} value={HTMLText} readOnly />
				<a href={url} target="_blank" rel="noreferrer" download={fileName}>
					<Fab title="Download infographic">
						<I icon={DownloadIcon} />
					</Fab>
				</a>
				<Fab title="Get infographic image html code" onClick={handleCodeClick}>
					<I icon={CodeIcon} />
				</Fab>
			</ActionsContainer>
			{notification ? <Notification ref={notificationRef}>{notification}</Notification> : null}
			<Media {...props} />
		</Container>
	)
}

export default React.memo(Infographic)
