import * as React from "react"
import { css } from "@emotion/react"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { HeadingAlign, IJsonContentfulField } from "common/types"

export const elementData = {
	section: "section",
	buttonContainer: "button-container",
	container: "container",
	textAndMediaContainer: "text-and-media-container",
	bannerTextContainer: "banner-text-container",
	bannerMedia: "banner-media",
	bannerMediaContainer: "banner-media-container",
	cover: "cover",
	textContainer: "text-container",
	cardContainer: "card-container",
	cardTitle: "card-title",
	formContainer: "form-container",
	gradientWrapper: "gradient-wrapper",
}

function getCSS(styles: Object) {
	const newStyles = []
	Object.keys(styles).forEach(selector => {
		let style = `${selector} {`

		Object.keys(styles[selector]).forEach(prop => {
			style += `${prop}: ${styles[selector][prop]};`
		})
		style += "}"

		newStyles.push(style)
	})

	return css`
		${newStyles.join("\n")}
	`
}

export const getCustomStyle = (field: IJsonContentfulField) => {
	if (!field || !field.internal || !field.internal.content) {
		return css``
	}

	const obj = JSON.parse(field.internal.content)
	let mobile = css``
	let desktop = css``
	let desktopMedium = css``
	let desktopLarge = css``
	if (obj.mobile) {
		mobile = getCSS(obj.mobile)
	}
	if (obj.desktop) {
		desktop = getCSS(obj.desktop)
	}
	if (obj.desktopMedium) {
		desktopMedium = getCSS(obj.desktopMedium)
	}
	if (obj.desktopLarge) {
		desktopLarge = getCSS(obj.desktopLarge)
	}

	return css`
		${mobile}
		${mediaBreakpoint(BreakPoints.SM)} {
			${desktop}
		}
		${mediaBreakpoint(BreakPoints.MD)} {
			${desktopMedium}
		}
		${mediaBreakpoint(BreakPoints.LG)} {
			${desktopLarge}
		}
	`
}

export const formatMultilineText = (heading: string) => {
	const parts = heading.trim().split("\n")
	const partsLength = parts.length

	return partsLength > 1 ? (
		<>
			{heading.split("\n").map((part, index) => {
				return (
					<React.Fragment key={`part-${index + 1}`}>
						{part} {index + 1 === partsLength ? null : <br />}
					</React.Fragment>
				)
			})}
		</>
	) : (
		heading
	)
}

export const getHeadingAlignStyles = (align: HeadingAlign | null) => {
	const headingAlign = align || HeadingAlign.Center

	switch (headingAlign) {
	case HeadingAlign.Right: {
		return css`
				justify-content: flex-end;
				text-align: right;
			`
	}
	case HeadingAlign.Left: {
		return css`
				justify-content: flex-start;
				text-align: left;
			`
	}
	case HeadingAlign.Center:
	default: {
		return css`
				justify-content: center;
				text-align: center;
			`
	}
	}
}
