import * as React from "react"
import { graphql } from "gatsby"
import { SpanH3 } from "components/headings"
import { css } from "@emotion/react"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { getWhiteText, theme } from "common/colorScheme"
import { elementData, getCustomStyle, getHeadingAlignStyles } from "components/sections/utils"
import { HeadingAlign, IJsonContentfulField } from "common/types"
import styled from "@emotion/styled"
import { EntryAnimations } from "components/ScrollAnimation"
import Container from "../container"
import TimelineCard, { TimelineCardProps } from "./TimelineCard"


interface Props {
	cards: TimelineCardProps[]
	entryAnimation: EntryAnimations
	heading?: string
	inverse: boolean
	whiteText?: boolean
	customSpacing?: IJsonContentfulField
	headingAlign: HeadingAlign | null
	anchor?: string
}


const textContainerStyles = css`
	width: 100%;
	margin: 0;
	align-self: center;
	margin-bottom: -30px;
	text-align: left;
	${mediaBreakpoint(BreakPoints.SM)} {
		text-align: center;
		width: 75%;
	}
`


const HeadingContainer = styled.div`
	display: flex;
	z-index: 1;
`


const containerStyles = css`
	display: flex;
	flex-direction: column;
`


const coverStyles = css`
	content-visibility: initial;
	padding-bottom: ${theme.spacing(7.5)};
`


const cardsContainer = css`
	${mediaBreakpoint(BreakPoints.SM)} {
		&:last-child {
			margin: 0 0 80px;
		}
	}
`

const timelineWrapper = css`
	height: 100%;
	width: 100%;
	position: relative;
	:before {	
		content: '';
		position: absolute;
		top: 130px;
		left: 25px;
		margin-left: -1px;
		border-left: 2px dotted #662ABC;
		height: 100%;
		${mediaBreakpoint(BreakPoints.LG)} {
			left: 50%;
			top: 155px;
		}	
	}
	::after {
		content: '';
		width: 10px;
		height: 10px;
		background-color: #662ABC;
		border-radius: 50%; 
		left: 20px;
		position: absolute;
		margin-top:4rem;
		${mediaBreakpoint(BreakPoints.LG)} {
			left: 50%;
    		margin-left: -5px;	
		}	
	}
`

const TimelineContainer = css`
	padding: 50px 0;
	margin: 50px auto;
	position: relative;
	overflow: hidden;
	
`
const Timeline: React.FC<Props> = ({
	cards,
	heading,
	entryAnimation,
	inverse,
	whiteText,
	customSpacing,
	headingAlign,
	anchor,
}) => {
	const customStyle = React.useMemo(() => getCustomStyle(customSpacing), [customSpacing])


	return (
		<section id={anchor} data-element="timeline-section" css={[coverStyles, customStyle, TimelineContainer, getCustomStyle(customSpacing)]}>
			<div css={timelineWrapper} data-element={elementData.gradientWrapper}>
				<Container css={[containerStyles]}>		
					{heading ? (
						<HeadingContainer data-element="container" css={[customStyle, getHeadingAlignStyles(headingAlign)]}>
							<div css={[textContainerStyles, getWhiteText(whiteText)]} data-element={elementData.bannerTextContainer}>
								<SpanH3>{heading}</SpanH3>
							</div>
						</HeadingContainer>
					) : null}					
					<div css={cardsContainer}>
						{cards.map((card, index) => {
							const { id } = card
							return (
								<TimelineCard
									key={id}
									even={inverse ? index % 2 !== 0 : index % 2 === 0}
									entryAnimation={entryAnimation}
									{...card}
								/>
							)
						})}
					</div>				
				</Container>
			</div>
		</section>
	)
}


export const query = graphql`
	fragment Timeline on ContentfulTimeline {
		__typename
		anchor
		heading
		headingAlign
		entryAnimation
		id
		inverse
		cards {
			...TimelineCard
		}
		whiteText
		customSpacing {
			internal {
				content
			}
		}
	}
`


export default React.memo(Timeline)