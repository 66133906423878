import * as React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { FieldError } from "react-hook-form"

import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { theme } from "common/colorScheme"
import { css , SerializedStyles } from "@emotion/react"
import { Select, SelectContainer } from "../Select"
import { isIOS } from "../utils"
import TextFieldComponent from "../TextField"
import ErrorLabel from "../ErrorLabel"
import TextArea from "../TextArea"


export enum FieldTypes {
	TextField = "text",
	EmailField = "email",
	TextAreaField = "textarea",
	SelectField = "select",
	SeparatorField = "separator",
}

export interface FormFieldBase {
	fieldName: string
	type: FieldTypes
	required: boolean
	message?: string
}

export interface FormFieldProps extends FormFieldBase {
	id: string
	label: string
	fullWidth: boolean
	options: string[] | null
	ref: ((instance: HTMLElement) => void) | React.MutableRefObject<HTMLElement>
	error?: FieldError
	purpleBorder?: boolean
	placeholder?: string
	hideLabel?: boolean
	customStyle?: SerializedStyles
}

const Label = styled.label`
	font-size: 1.6rem;
	line-height: 2.8rem;
	font-weight: 800;
	margin-bottom: ${theme.spacing(1)};
`

const screenReaderOnly = css`
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
`

const Optional = styled.span`
	font-weight: normal;
	margin: 0 0 0 4px;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 0 12px;
	flex-basis: 100%;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: calc(50% - 12px);
		margin: 9px 0 16px;
		&[data-fullwidth="true"] {
			flex-basis: 100%;
		}
	}
`

const Separator = styled.div`
	border-top: 1px solid #ebeaeb;
	margin: 3rem 0 2rem;
`

const FormField: React.FC<FormFieldProps> = React.forwardRef(
	(
		{
			id,
			label,
			fullWidth,
			fieldName,
			type,
			required,
			options,
			error,
			purpleBorder,
			placeholder,
			hideLabel,
			customStyle,
		},
		ref
	) => {
		const containerRef = React.useRef<HTMLDivElement>()

		const Component = React.useMemo(() => {
			const invalid = Boolean(error)

			switch (type) {
			case FieldTypes.TextField:
			case FieldTypes.EmailField: {
				return (
					<TextFieldComponent
						ref={ref as React.MutableRefObject<HTMLInputElement>}
						id={id}
						type={type}
						name={fieldName}
						data-invalid={invalid}
						purpleBorder={purpleBorder}
						placeholder={placeholder || ""}
					/>
				)
			}
			case FieldTypes.TextAreaField: {
				return (
					<TextArea
						ref={ref as React.MutableRefObject<HTMLTextAreaElement>}
						id={id}
						name={fieldName}
						data-invalid={invalid}
						purpleBorder={purpleBorder}
						placeholder={placeholder || ""}
					/>
				)
			}
			case FieldTypes.SelectField: {
				return (
					<SelectContainer>
						<Select
							ref={ref as React.MutableRefObject<HTMLSelectElement>}
							name={fieldName}
							data-invalid={invalid}
							purpleBorder={purpleBorder}
						>
							<option value="">Please select one</option>
							{(options || []).map(option => {
								return (
									<option value={option} key={option}>
										{option}
									</option>
								)
							})}
						</Select>
					</SelectContainer>
				)
			}
			case FieldTypes.SeparatorField: {
				return <Separator />
			}
			default: {
				return null
			}
			}
		}, [fieldName, type, id, ref, error])

		React.useEffect(() => {
			const container = containerRef.current
			if (isIOS() && type === FieldTypes.SelectField && container) {
				container.querySelector("select").appendChild(document.createElement("optgroup"))
			}
		}, [])

		return (
			<Container ref={containerRef} data-fullwidth={fullWidth} css={[customStyle]}>
				{type !== "separator" && (
					<Label htmlFor={id} css={[hideLabel && screenReaderOnly]}>
						{label}
						{!required ? <Optional>(optional)</Optional> : <span> * </span>}
					</Label>
				)}
				{Component}
				{error ? <ErrorLabel>{error.message}</ErrorLabel> : null}
			</Container>
		)
	}
)

export const query = graphql`
	fragment FormField on ContentfulFormField {
		__typename
		id
		label
		fieldName
		fullWidth
		required
		type
		options
	}
`

export default React.memo(FormField)
