import { ButtonProps } from 'components/button'
import { CardProps } from 'components/sections/Card/Card'
import { SectionLayoutAlign, IMarkdown } from 'components/sections/section.model'

export enum IAccordionVariant {
  Default = 'Default',
  Light = 'Light',
  Dark = 'Dark',
  Transparent = 'Transparent',
}

export interface IAccordionItemProps {
  id: string;
  title: string;
  description: string;
  onToggle: (event: React.MouseEvent<HTMLButtonElement>) => void;
  active: boolean;
  accordionItemButton: ButtonProps[];
  accordionItemImage: any;
  accordionCard: CardProps[];
  subItems: IAccordionItemProps[];
  alternativeText: IMarkdown
}

export interface IAccordionProps {
  items: IAccordionItemProps[];
  [key: string]: any;
  layoutAlignment: SectionLayoutAlign;
  variant: IAccordionVariant | null;
}
