import * as React from "react"
import { ContentfulTypes } from "common/types"
import AlternatingCards from "components/AlternatingCards"
import BrandImages from "components/BrandImages"
import BrandImagesWithReviews from "components/BrandImagesWithReviews"
import Featured from "components/Featured"
import GridItem from "components/GridItem/GridItem"
import { MediaProps } from "components/Media"
import ReferencesSection from "components/ReferencesSection"
import Section from "components/sections"
import BrandCategorySection from "components/BrandCategory"
import EmbedSpotifySection from "components/EmbedSpotifySection"
import Timeline from "components/Timeline"
import ArticlesForm from "components/Form/ArticlesForm"

interface IModuleRenderProps {
	sections: any[]
	secondaryImage?: MediaProps
}

const ModuleRender: React.FC<IModuleRenderProps> = ({ sections, secondaryImage }) => {
	return (
		<>
			{(sections || []).map(section => {
				const { id, __typename } = section

				switch (__typename) {
				case ContentfulTypes.Section:
					return <Section key={id} secondaryImage={secondaryImage} {...section} />
				case ContentfulTypes.BrandCategorySection:
					return <BrandCategorySection key={id}  {...section} />
				case ContentfulTypes.AlternatingCards:
					return <AlternatingCards key={id} {...section} />
				case ContentfulTypes.BrandImages:
					return <BrandImages key={id} {...section} />
				case ContentfulTypes.BrandImagesWithReviews:
					return <BrandImagesWithReviews key={id} {...section} />
				case ContentfulTypes.Grid:
					return <GridItem key={id} {...section} />
				case ContentfulTypes.ReferencesSection:
					return <ReferencesSection key={id} {...section} />
				case ContentfulTypes.Featured:
					return <Featured key={id} {...section} />
				case ContentfulTypes.EmbedSpotifySection:
					return <EmbedSpotifySection key={id} {...section} />
				case ContentfulTypes.Timeline:
					return <Timeline key={id} {...section} />
				case ContentfulTypes.Form:
					return <ArticlesForm key={id} {...section} />
				default: {
					return null
				}
				}
			})}
		</>
	)
}

export default React.memo(ModuleRender)
