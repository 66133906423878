import styled, { StyledComponent } from '@emotion/styled'
import { mediaBreakpoint, BreakPoints } from 'settings/breakpoints'
import { SpanH2 } from 'components/headings'
import { ChevronIcon } from 'components/icons'

export const AccordionSection = styled.div`
  width: 100%;
`

interface ItemProps {
  hideDesktop?: boolean;
  hideMobile?: boolean;
}
export const Item: StyledComponent<{}, {}, ItemProps> = styled.div<ItemProps>`
  background: transparent;
  width: -webkit-fill-available;
  display: ${(props) => (props.hideMobile ? 'none' : 'block')};
  ${mediaBreakpoint(BreakPoints.LG)} {
    display: ${(props) => (props.hideDesktop ? 'none' : 'flex')};
    justify-content: space-between;
  }
`

export const ItemContainer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  &.active.expanding {
    max-height: 1000px;
    transition: max-height 0.5s ease-in;
  }
`

export const ItemIcon = styled.div`
  display: none;
  background-color: black;
  width: 8px;
  height: 8px;
  margin-left: 13px;
  mask-image: url('${ChevronIcon}');
  mask-size: cover;
  transition: transform 0.5s;
  &.expanding {
    display: block;
  }
  &.active.expanding {
    display: block;
    transform: rotate(180deg);
    background-color: #9450ff;
  }
`

export const Title = styled.button`
  border-radius: 10px 10px 0 0;
  background-color: transparent;
  color: #fff;
  text-align: left;
  font-weight: 700;
  display: flex;
  &.child {
    margin-left: 20px;
  }
  &.parent:hover .expanding {
    background-color: #9450ff;
  }
  &.parent:hover ${SpanH2} {
    color: #9450ff;
  }
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 0 0 0;
  cursor: pointer;
  border: none;
  &.active {
    background-color: transparent;
  }
  ${SpanH2} {
    font-size: 2.4rem;
    color: #fff;
    line-height: 3rem;
    padding: 0;
  }
  ${mediaBreakpoint(BreakPoints.LG)} {
    padding: 1.8rem 2.4rem 0 2.4rem;
    background-color: transparent;
    &.active {
      background-color: #28056160;
    }
  }
`
export const Content = styled.div`
  width: 100%;
  ${mediaBreakpoint(BreakPoints.LG)} {
    width: 385px;
    justify-content: end;
    /* flex-basis: 40% */
    /* display:flex; */
  }
`
export const Panel = styled.div`
  border-radius: 0 0 10px 10px;
  height: 0px;
  overflow: hidden;
  &.active {
    height: auto;
    background-color: transparent;
  }
  ${mediaBreakpoint(BreakPoints.LG)} {
    opacity: 0;
    &.active {
      opacity: 1;
      background-color: #28056160;
    }
  }
`
export const Description = styled.div`
  font-size: 1.6rem;
  padding: 0 0 1.8rem 0;
  p {
    line-height: 2.6rem;
  }
  ${mediaBreakpoint(BreakPoints.LG)} {
    padding: 0 2.4rem 1.8rem 2.4rem;
  }
`
export const AccordionButton = styled.div`
  padding: 0 0 1.8rem 0;
  button {
    width: auto;
  }
  ${mediaBreakpoint(BreakPoints.LG)} {
    padding: 0 2.4rem 1.8rem 2.4rem;
  }
`
export const Image = styled.div`
  display: none;
  &.active {
    display: block;
    position: relative;
    height: auto;
    width: 100%;
    top: 0rem;
  }

  ${mediaBreakpoint(BreakPoints.LG)} {
    &.active {
      width: auto;
      padding-right: 0rem;
    }
  }
`

export const AccordionMedia = styled.div`
  display: flex;
  flex-basis: 60%;
  ${mediaBreakpoint(BreakPoints.LG)} {
    &.active {
      padding-right: 0rem;
    }
  }
`
export const OnlyMobileImages = styled.div`
  display: block;
  ${mediaBreakpoint(BreakPoints.LG)} {
    display: none;
  }
`
export const OnlyDesktopImages = styled.div`
  display: none;
  ${mediaBreakpoint(BreakPoints.LG)} {
    display: block;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`

export const HeaderText = styled.h2`
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 800;
  letter-spacing: 0.05em;
`

interface MenuButtonProps {
  show: boolean;
  icon: any;
  onClick: any;
}
export const MenuButton: StyledComponent<
  {},
  {},
  MenuButtonProps
> = styled.button`
  display: ${(props) => (props.show ? 'block' : 'none')};
  padding: 0;
  width: 18px;
  height: 15px;
  border: none;
  background: ${(props: MenuButtonProps) =>
		`url('${props.icon}') no-repeat center center`};
  background-size: cover;
`

export const MenuPanel = styled.div`
  border-radius: 0 0 10px 10px;
  background-color: #f9f9f9;
  margin: 0px -36px;
  padding: 0 36px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  &.active {
    max-height: 1000px;
    transition: max-height 0.75s ease-in;
  }
`
