import * as React from "react"
import { graphql } from "gatsby"
import { css, SerializedStyles } from "@emotion/react"
import styled from "@emotion/styled"
import Media from "components/Media"
import { elementData, getCustomStyle } from "components/sections/utils"
import { getWhiteText, theme } from "common/colorScheme"
import ScrollAnimation, { EntryAnimations } from "components/ScrollAnimation"
import { IJsonContentfulField } from "common/types"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { P, SpanH4 } from "../headings"


type AlignType = "center" | "end"


export interface TimelineCardProps {
	id: string
	heading: string
	entryAnimation: EntryAnimations
	description: {
		description: string
	} | null
	media: any
	even: boolean
	textForPointer?: string
	align: AlignType
	whiteText?: boolean
	customStyles?: SerializedStyles
	customSpacing?: IJsonContentfulField
}


interface IMediaContainerProps {
	even: boolean
}


interface IStyledContainerProps {
	customStyles?: any
	align: AlignType
}


const MediaContainer = styled.div<IMediaContainerProps>`
	display: none;
	order: 0;
	flex-basis: calc(50% - 16px);
	align-self: center;

	${mediaBreakpoint(BreakPoints.SM)} {
		display: block;
		font-size: 1.6rem;
		line-height: 2.4rem;
		max-width: 556px;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		margin-left: ${({ even }) => (even ? theme.spacing(2) : "0")};
		margin-right: ${({ even }) => (!even ? theme.spacing(2) : "0")};
	}
`


const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	order: 1;
	flex-basis: 100%;
	max-width: 100%;
	z-index: 1;
	padding-top: 25px;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: calc(50% - 16px);
		max-width: 540px;
	}
`


const AdditionalTopContent = css`
	display: flex;
	align-items: center;
	margin-bottom: ${theme.spacing(2.6)};
	.gatsby-image-wrapper {
		margin-right: ${theme.spacing(3)};
	}
	span {
		padding-bottom: 0rem;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		span {
			padding-bottom: 2rem;
		}
	}
`
const Point = styled.div `
	width: 10px;
	height: 10px;
	background-color: #662ABC;
	border-radius: 50%; 
	left: 20px;
    position: absolute;
	margin-top: 0px;
	 ${mediaBreakpoint(BreakPoints.LG)} {
		width: 10px;
		height: 10px;
		background-color: #662ABC;
		border-radius: 50%; 
		left: 50%;
		margin-left: -5px;
		position: absolute;
		margin-top: 0px;
		:after {	
			content: '';
			position: absolute;
			border-left: 50px solid #662ABC;
			height: 1px;
			left: 25px;
			top: 3px;
	 	}
	}
	@media only screen 
		and (min-device-width: 767px) 
		and (max-device-width: 1024px) 
		and (-webkit-min-device-pixel-ratio: 1) {
		:after {	
			content: '';
			position: absolute;
			display:none;
	 	}
}
`




const StyledContainer = styled.div<IStyledContainerProps>`
	display: flex;
	align-items: ${({ align }) => {
		switch (align) {
		case "center":
			return "center"
		case "end":
			return "flex-end"
		default:
			return "flex-start"
		}
	}};
	justify-content: space-between;
	align-items: center;
	padding: 4.2rem 0 0;
	margin: 0;
	flex-direction: column;
	${({ customStyles }) => customStyles}
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: 12.2rem 0 0;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		flex-direction: row;
		align-items: initial;
	}
	&[data-even="true"] {
		${MediaContainer} {
			order: 1;
		}
		${TextContainer} {
			order: 0;
			text-align:left;
			${mediaBreakpoint(BreakPoints.LG)} {
				text-align:right;
			}
		}
		${SpanH4} {
			align-self: self-start;
			${mediaBreakpoint(BreakPoints.LG)} {
				align-self: end;
			}
		}
		${Point} {
			:after {	
				content: '';
				position: absolute;
				border-left: 50px solid #662ABC;
				height: 1px;
				left: -70px;
				top: 5px;
			 }
		}
		[data-element = "additional-top-content"] {
			align-self: self-start;
			${mediaBreakpoint(BreakPoints.LG)} {
				align-self: end;
			}
		}
	}

	&[data-even="false"] {
		flex-direction: column-reverse;
		${mediaBreakpoint(BreakPoints.LG)} {
			flex-direction: row;
		}
	}
`


const mobileMediaStyles = css`
	display: block;
	margin: 24px 0;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: none;
	}
`


const TimelineCard: React.FC<TimelineCardProps> = ({
	heading,
	description,
	media,
	even,
	textForPointer,
	entryAnimation,
	align,
	whiteText,
	customStyles,
	customSpacing
}) => {


	return (
		<ScrollAnimation type={entryAnimation} alternate={even}>
			<div css={getCustomStyle(customSpacing)}>
				<StyledContainer
					customStyles={customStyles}
					data-even={even}
					data-element={elementData.cardContainer}
					align={align}
				>
					
					<TextContainer data-element= "timeline-cards-text" css={getWhiteText(whiteText)}>
						{textForPointer ? (
							<div css={AdditionalTopContent} data-element= "additional-top-content">
								<span><Point />{textForPointer}</span>
							</div>
						) : null}
						{heading ? <SpanH4 data-element= "heading">{heading}</SpanH4> : null }	
						{description ? <P>{description.description}</P> : null}
						{media ? (
							<div css={mobileMediaStyles}>
								<Media {...media} />
							</div>
						) : null}
					</TextContainer>
					<MediaContainer data-element= "timeline-cards-media" even={even}>
						{media ? (
							<div style={{ position: "relative", maxWidth: "100%" }}>
								<Media {...media} />
							</div>
						) : null}{" "}
					</MediaContainer>
				</StyledContainer>
			</div>
		</ScrollAnimation>
	)
}


export const query = graphql`
	fragment TimelineCard on ContentfulTimelineTimelineCard {
		__typename
		id
		heading
		description {
			description
		}
		media {
			...Media600
		}
		heading
		textForPointer
		align
		whiteText
		customSpacing {
			internal {
				content
			}
		}
	}
`


export default React.memo(TimelineCard)