import * as React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Button, { IButtonVariants } from "components/button"
import { css } from "@emotion/react"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import * as hookform from "@hookform/resolvers"
import { ErrorOption, useForm } from "react-hook-form"
import hubspot from "scripts/hubspot"
import { SuccessIcon } from "components/icons"
import { SectionThemeContext } from "components/sections/SectionThemeContext"
import { elementData } from "components/sections/utils"
import { StringParam, useQueryParam } from "use-query-params"
import Analytics from "scripts/analytics"
import Helmet from "react-helmet"
import { useLocation } from "@reach/router"
import { generateSchema, generateDefaultValues } from "./utils"
import FormField, { FormFieldProps } from "./FormField"

const FormBase = styled.form`
	display: flex;
	flex-direction: column;
	background: #fff;
	padding: 52px 36px 64px;
	margin: 0;
	position: relative;
	color: var(--text-color);
	border-radius: 0px;
	${mediaBreakpoint(BreakPoints.SM)} {
		border-radius: 4px;
		padding: 28px 32px 32px;
		box-shadow: 7px 7px 18px 0 rgba(35, 35, 35, 0.1);
	}
	&[aria-busy="true"] {
		pointer-events: none;
		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: rgba(255, 255, 255, 0.7);
		}
	}
`

const FieldsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
`

const PrivacyConsent = styled.span`
	display: block;
	font-size: 1.6rem;
	font-weight: normal;
	line-height: 2rem;
	color: #7b7b7b;
	margin: 0 0 16px;
	> a {
		color: inherit;
		font-weight: 800;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		line-height: 2.8rem;
	}
`

const submitStyles = css`
	margin: 16px 0 0;
	min-width: 192px;
	width: 100%;

	${mediaBreakpoint(BreakPoints.SM)} {
		width: auto;
	}

	&:hover {
		background: linear-gradient(45deg, var(--secondary-dark), var(--secondary-light));
		background-size: 200% 200%;
		background-position: 100% 100%;
		transition: 0.3s background-position linear, 0.3s background-size linear;
	}
`

const MessageOverlay = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 3;
	position: absolute;
	background: #fff;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 32px;
`

const MessageOverlaySeparator = styled.div`
	width: 100%;
	height: 1px;
	background: #ebeaeb;
	margin: 32px 0 28px;
`

const MessageOverlayText = styled.div`
	max-width: 330px;
	font-size: 2rem;
	line-height: 2.8rem;
	text-align: center;
`

const MessageOverlayIcon = styled.div`
	width: 78px;
	height: 78px;
	background: url("${SuccessIcon}") no-repeat center center;
`
const FormTitle = styled.h3`
	font-weight: 800;
	font-size: 2rem;
	margin-bottom: 2rem;

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 3rem;
		margin-bottom: 2rem;
	}
`

export interface FormProps {
	id: string
	formId: string
	hubspotFormId: string
	contentfulfields: FormFieldProps[]
	privacyConsent: boolean | null
	submitText: string
	purpleBorder?: boolean
	buttonVariant?: IButtonVariants
	bannerTitle: string
}

interface SubmitMessage {
	type: "success" | "error"
	message: string
}

const Form: React.FC<FormProps> = ({
	formId,
	hubspotFormId,
	contentfulfields,
	privacyConsent,
	submitText,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	id: _id, // prevent Contentful Id from setting the form id
	purpleBorder,
	buttonVariant,
	bannerTitle,
	...other
}) => {
	const [, setFormParam] = useQueryParam("form", StringParam)
	const [submitedMessage, setSubittedMessage] = React.useState<SubmitMessage>()
	const [pending, setPending] = React.useState(false)
	const btnVariant = buttonVariant || "primary"
	const { href } = useLocation()

	const schema = React.useMemo(() => {
		return generateSchema(contentfulfields)
	}, [contentfulfields])

	const defaultValues = React.useMemo(() => {
		return generateDefaultValues(contentfulfields)
	}, [contentfulfields])

	const { register, handleSubmit, errors, setError } = useForm({
		defaultValues,
		resolver: hookform.yupResolver(schema),
	})

	const isIndustryInsightsPage : boolean = /industry-insights/.test(href)
	const linkedInPixelMap: {[key: string]: string} = {
		'BookDemo': '6145625',
		'patreon-brand-bite-form': '6145849',
		'refining-your-target-audience-form': '5340409',
		'handle-brand-crisis': '6145857',
		'v-label-brand-bite-form': '6289313',
		'vinted-brand-bite-form': '6289329',
		'consumer-insights-email-series': '6289441',
		'lush-brand-bite-form': '6289457',
		'wish-brand-bite-form': '5876537',
		'clue-form': '5692265',
		'patagonia-brand-bite-form': '6417761',
		'treatwell-brand-bite-form': '6550017',
		'holiday-marketing-guide': '6550225',
		'JBL-brand-bite-form': '6845449',
		'loyalty-marketing-form': '7109665',
		'Future-of-marketing-webinar': '7109657',
		'conducting-competitor-research-form': '7109673',
		'Speaking-to-Gen-Z-form': '7109681',
		'pure-gym-brand-bite-form': '7188401',
		'brewdog-brand-bite-form': '7342305',
		'pandora-brand-bite-form': '7536401',
		'apparel-brand-battle-form': '7536409',
		'crocs-brand-bite-form': '7536433',
		'ecommerce-brand-battle-form': '7753945',
		'Speaking-to-gen-x-form': '7753953',
		'cosmetics-brand-battle-form': '7536417',
		'too-faced-brand-bite-form': '8186673',
		'health-and-wellness-brand-battle-form': '8186681',
		'the-north-face-brand-bite-form': '8039201',
		'Customer-Centricity-guide': '8479105',
		'consumer-self-awareness-form': '8479193',
		'consumer-insights-report-2022-form': '8528473',
		'honest-brand-bite-form': '8528497',
		'netflix-brand-bite-form': '8528505',
		'aliexpress-brand-bite-form': '8528513',
		'meal-kit-delivery-brand-battle-form': '9040161',
		'footwear-brand-battle-form': '9040169',
		'rethinking-your-paid-socials-form': '9040441',
		'social-commerce-report-2022-form': '9040449',
		'industry-interest-form':'9218009',
		'industry-interest-form-ebikes': '9218017',
		'industry-interest-form-elearning': '9218025',
		'industry-interest-form-mattress':'9218033',
		'kiehls-brand-bite-form': '9327169',
		'mobility-brand-battle-form': '9327185',
		'8fit-brand-bite-form': '9327193',
		'Data-Quality-guide':'9327201',
		'pokemon-go-brand-bite-form': '9706041',
		'Brand-Associations-guide': '9706017',
		'news-entertainment-brand-battle-form': '9706009',
		'tinder-brand-bite-form': '9706169',
		'ethics-in-marketing-report-form': '9706129',
		'digital-communication-report-form': '9972505'
	}
	const getLinkedinPixelCode: string = linkedInPixelMap[formId] 

	const onSubmit = React.useCallback(
		async (data: any) => {
			setPending(true)

			try {
				const { inlineMessage } = await hubspot.submitForm(hubspotFormId, data)

				if (formId) {
					const dataLayer = (window as any).dataLayer || []
					dataLayer.push({ event: formId })
				}

				const { firstname, lastname, email, company, entity_category: entityCategory } = data
				const attribution = (window as any).Attribution || { identify: () => null, track: () => null }

				attribution.identify({
					name: `${firstname && firstname} ${lastname && lastname}`,
					email,
					company,
					entityCategory,
				})

				attribution.track("form submitted", {
					id: formId,
				})
				const { gtag } = window as any
				gtag('event', 'conversion', {'send_to': 'G-5WT0H2TSDG/bzOtCNCt4PYCEK2in8wD'})
				setSubittedMessage({
					type: "success",
					message: inlineMessage,
				})
				setFormParam("submitted")
			} catch (error) {
				if (error.errors) {
					const hubspotErrors = hubspot.getErrors(error, contentfulfields)
					const errorKeys = Object.keys(hubspotErrors)
					errorKeys.forEach(fieldName => {
						setError(
							fieldName as never,
							{
								message: hubspotErrors[fieldName],
							} as ErrorOption
						)
					})

					const { ga } = window as any

					if (errorKeys.length && ga) {
						Analytics.freeEmailBlocked(formId)
					}
				} else {
					setSubittedMessage({
						type: "error",
						message: "Failed to submit the form, please try again later",
					})
				}
			}
			setPending(false)
		},
		[hubspotFormId]
	)
	
	return (
		<SectionThemeContext.Provider value="default">
			<FormBase
				id={formId}
				onSubmit={handleSubmit(onSubmit)}
				data-element={elementData.formContainer}
				aria-busy={pending}
				{...other}
			>
				{formId && linkedInPixelMap[formId] && submitedMessage && (
					<>
						<img height="1" width="1" style={{display:"none"}} alt="" src="https://px.ads.linkedin.com/collect/?pid=1088361&fmt=gif}"/>
						{formId === 'BookDemo' && <script>{` obApi("track", "Lead"); `} </script> }
						<Helmet>
							<script type="text/javascript">
								{`window.lintrk('track', { conversion_id: ${getLinkedinPixelCode} });`}
							</script>
						</Helmet>
					</>
				)}
				{formId && isIndustryInsightsPage && submitedMessage && <script>{` gtag("event", "conversion", {"send_to": "G-5WT0H2TSDG/KUnMCKzb-sYDEK2in8wD"});`} </script> }
				{submitedMessage ? (
					<MessageOverlay data-element="overlay">
						<MessageOverlayIcon data-element="icon" />
						<MessageOverlaySeparator data-element="separator" />
						<MessageOverlayText
							data-element="text"
							dangerouslySetInnerHTML={{
								__html: submitedMessage.message,
							}}
						/>
					</MessageOverlay>
				) : null}
				{bannerTitle && <FormTitle>{bannerTitle}</FormTitle> }
				<FieldsContainer>
					{contentfulfields.map(field => {
						const { id, fieldName } = field
						return (
							<FormField ref={register} key={id} error={errors[fieldName]} purpleBorder={purpleBorder} {...field} />
						)
					})}
				</FieldsContainer>
				<div>
					{privacyConsent ? (
						<PrivacyConsent>
							By clicking “{submitText}”, I acknowledge receipt of the Latana{" "}
							<a href="https://www.latana.com/legal-terms/privacy-policy" target="_blank" rel="noopener noreferrer">
								Privacy Policy
							</a>
							.
						</PrivacyConsent>
					) : null}
					<Button css={submitStyles} label={submitText} type="submit" variant={btnVariant} />
				</div>
			</FormBase>
		</SectionThemeContext.Provider>
	)
}

export const query = graphql`
	fragment Form on ContentfulForm {
		__typename
		id
		hubspotFormId
		formId
		privacyConsent
		submitText
		purpleBorder
		buttonVariant
		bannerTitle
		bannerSubtitle
		bannerGradient
		contentfulfields {
			...FormField
		}
	}
`

export default React.memo(Form)
