/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, { useState, useRef } from 'react'
import Button from 'components/button'
import Container from "components/container"
import Media from 'components/Media'
import { SpanH2 } from 'components/headings'
import Card from 'components/sections/Card/Card'
import {AdditionalText} from "components/sections/ImageRight"
import { ChevronIcon } from 'components/icons'
import { theme } from 'common/colorScheme'
import {
	AccordionButton,
	AccordionMedia,
	Content,
	Description,
	Item,
	Image,
	OnlyDesktopImages,
	OnlyMobileImages,
	Panel,
	Title,
	ItemContainer,
	ItemIcon,
} from './components'
import {
	IAccordionItemProps,
	IAccordionProps,
	IAccordionVariant,
} from './shared'
import { getActiveClass, isItemActive, isPanelActive } from './utils'

const DefaultVariant: React.FC<IAccordionProps> = ({ items = [], variant }) => {
	const [activeProperties, setActivePropeties] = useState([0, 0])
	const onTitleClick = (index, level) => {
		if (level === null) {
			setActivePropeties([index, 0])
		} else {
			setActivePropeties([index, level])
		}
	}
	const contentEl = useRef()

	const getItem = ({
		item,
		parentIndex,
		subIndex = null,
	}: {
    item: IAccordionItemProps;
    parentIndex: number;
    subIndex?: number;
  }) => {
		const [activeParentIndex, activeSubIndex] = activeProperties
		const active = isItemActive({
			parentIndex,
			subIndex,
			activeParentIndex,
			activeSubIndex,
		})
		const { id, subItems } = item

		const itemClass = subIndex === null ? 'parent' : 'child'
		const itemExpandingClass = subItems && subItems.length ? 'expanding' : ''
		const itemClassName = `${getActiveClass(active)} ${itemExpandingClass}`

		return (
			<>
				<div key={id}>
					<Title
						onClick={() => onTitleClick(parentIndex, subIndex)}
						className={`${getActiveClass(active)} ${itemClass}`}
					>
						<SpanH2>{item.title}</SpanH2>
						<ItemIcon
							className={itemClassName}
							src={ChevronIcon}
							alt="chevron"
						/>
					</Title>
					<Panel className={getActiveClass(active)} ref={contentEl}>
						{item.alternativeText && (
							<AdditionalText
								sectionTheme={theme}
								dangerouslySetInnerHTML={{ __html: item.alternativeText?.childMarkdownRemark.html }}
							/>
						)}
						{item.description ? (
							<Description>
								<p>{item.description}</p>
							</Description>
						) : null}
						{item.accordionItemButton ? (
							<AccordionButton>
								<Button {...item.accordionItemButton} />
							</AccordionButton>
						) : null}
						<OnlyMobileImages>
							<Image className={getActiveClass(active)}>
								<Media {...item.accordionItemImage} />
								<Card {...item.accordionCard} />
							</Image>
						</OnlyMobileImages>
					</Panel>
				</div>
				<ItemContainer className={itemClassName}>
					{(subItems || []).map((subItem, index) =>
						getItem({ item: subItem, parentIndex, subIndex: index })
					)}
				</ItemContainer>
			</>
		)
	}

	const getImage = ({ item, parentIndex, subIndex = null }) => {
		const { id, subItems } = item
		const [activeParentIndex, activeSubIndex] = activeProperties
		const active = isPanelActive({
			parentIndex,
			subIndex,
			activeParentIndex,
			activeSubIndex,
			subItems,
		})

		return (
			<>
				<OnlyDesktopImages key={`media${id}`}>
					<Image className={active ? 'active' : ''}>
						<Media {...item.accordionItemImage} />
						<Card {...item.accordionCard} />
					</Image>
				</OnlyDesktopImages>
				{(subItems || []).map((item, index) =>
					getImage({ item, parentIndex, subIndex: index })
				)}
			</>
		)
	}

	return (
		<Item hideMobile={variant === IAccordionVariant.Transparent}>
			<AccordionMedia data-element="accordion-media">
				{items.map((item, index) => getImage({ item, parentIndex: index }))}
			</AccordionMedia>
			<Content data-element="content">
				{items.map((item, index) => getItem({ item, parentIndex: index }))}
			</Content>
		</Item>
	)
}

export default DefaultVariant
