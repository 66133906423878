import { css } from '@emotion/react'
import { SpanH2 } from 'components/headings'
import { mediaBreakpoint, BreakPoints } from 'settings/breakpoints'
import { AdditionalText } from 'components/sections/ImageRight'
import { AccordionMedia, Content, Description, Item, Panel, Title } from './components'
import { IAccordionVariant } from './shared'

export const alignContentFirsttStyles = css`
  ${mediaBreakpoint(BreakPoints.SM)} {
    ${AccordionMedia} {
      order: 2;
      margin-top: -30px;
    }
    ${Content} {
      order: 1;
      margin-top: 0;
    }
    ${Item} {
      ${mediaBreakpoint(BreakPoints.LG)} {
        width: 100%;
      }
    }
  }
`

export const alignMediaFirstStyles = css`
  ${Content} {
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
    order: 2;
    margin-right: 5% !important;
  }

  ${AccordionMedia} {
    order: 1;
  }
`

export const getVariantStyles = (variant: IAccordionVariant) => {
	switch (variant) {
	case IAccordionVariant.Transparent:
		return css`
        ${Panel} {
          &.active {
            color: #000000;
          }
          ${mediaBreakpoint(BreakPoints.MD)} {
            &.active {
              color: #000000;
            }
          }
        }
        ${Title} {
          background-color: transparent;
          padding: 0;
          &.active {
            background-color: transparent;
            span {
              color: #9450ff;
            }
          }
          ${SpanH2} {
            color: #010101;
            font-size: 1.2rem;
            font-weight: 800;
            margin: 0;
            text-transform: uppercase;
            &:hover {
              color: #9450ff;
            }
            &:focus {
              color: #9450ff;
            }
          }
        }
        ${AccordionMedia} {
          padding-bottom: 70px;
        }
      `

	case IAccordionVariant.Light:
		return css`
    ${AdditionalText} {
      padding: 0;
      ${mediaBreakpoint(BreakPoints.MD)} {
        padding: 0 2.4rem 2rem;
      }
    }
        ${Description} {
          padding: 1rem 0rem;
          background-color: transparent;
          ${mediaBreakpoint(BreakPoints.MD)} {
            padding: 1rem 0 1rem 2.4rem;
            &.active {
              background-color: #F8F8F8;
              color: #000;
            }
          }
        }
        ${Panel} {
          &.active {
            color: #000;
          }
          ${mediaBreakpoint(BreakPoints.MD)} {
            &.active {
              background-color: #F8F8F8;
              color: #000;
            }
          }
        }
        ${Title} {
          background-color: transparent;
          padding: 1rem 0 1rem 0rem;
          &.active {
            background-color: transparent;
            span {
              color: #000;
            }
          }
          ${SpanH2} {
            color: #000;
            font-weight: 800;
            margin: 0;
            &:hover {
              color: #000;
            }
            &:focus {
              color: #000;
            }
          }
          &.parent:hover .expanding {
            background-color: #000;
          }
          &.parent:hover ${SpanH2} {
            color: #000;
          }
          ${mediaBreakpoint(BreakPoints.MD)} {
            padding: 1rem 0 1rem 2.4rem;
            &.active {
              background-color: #F8F8F8;
              color: #000;
            }
          }
        }
      `
	case IAccordionVariant.Dark:
		return css`
            ${AccordionMedia} {
              margin-top: 0px;
            }
            ${AdditionalText} {
              padding: 0;
              ${mediaBreakpoint(BreakPoints.MD)} {
                padding: 0 2.4rem 2rem;
              }
            }
            ${Description} {
              padding: 1rem 0rem;
              background-color: transparent;
              ${mediaBreakpoint(BreakPoints.MD)} {
                padding: 1rem 0 1rem 2.4rem;
                &.active {
                  background-color: #fff;
                  color: #000;
              }
          }
            }
            ${Panel} {
              &.active {
                color: #000;
              }
              ${mediaBreakpoint(BreakPoints.MD)} {
                &.active {
                  background-color: #fff;
                  color: #000;
                }
              }
            }
            ${Title} {
              background-color: transparent;
              padding: 1rem 0 1rem 0rem;
              &.active {
                padding: 1rem 0 1rem 0rem;
                background-color: transparent;
                span {
                  color: #000;
                }
              }
              ${SpanH2} {
                color: #000;
                font-weight: 800;
                margin: 0;
                &:hover {
                  color: #000;
                }
                &:focus {
                  color: #000;
                }
              }
              &.parent:hover .expanding {
                background-color: #000;
              }
              &.parent:hover ${SpanH2} {
                color: #000;
              }
              ${mediaBreakpoint(BreakPoints.MD)} {
                padding: 1rem 0 1rem 2.4rem;
                &.active {
                  padding: 1rem 0 1rem 2.4rem;
                  background-color: #fff;
                  color: #000;
                }
              }
            }
          `
	case IAccordionVariant.Default:
	default:
		return css``
	}
}
