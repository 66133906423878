import * as React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import Media from "components/Media"
import { H6 } from "../headings"

const iconStyles = css`
	max-width: 48px;
	width: 48px;
	margin: 0 24px 0 0;
`

const headingStyles = css`
	display: flex;
	align-items: center;
	flex-direction: row;
`

export interface CategoryProps {
	title: string
	icon: any | null
}

const Category: React.FC<CategoryProps> = ({ icon, title }) => {
	return (
		<H6 data-element="category" css={headingStyles}>
			{icon ? (
				<Media
					css={iconStyles}
					imgStyle={{
						objectFit: "contain",
					}}
					{...icon}
				/>
			) : null}
			{title}
		</H6>
	)
}

export const query = graphql`
	fragment Category on ContentfulCategory {
		__typename
		title
		icon {
			...MediaIcon
		}
	}
`

export default React.memo(Category)
