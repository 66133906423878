import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { ButtonBase } from "components/button"
import Media from "components/Media"
import * as React from "react"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import Link from "components/Link"
import { theme } from "common/colorScheme"
import { Filter } from "components/ReferencesSection"
import { filterStyles } from "pages/articles"
import { navigate } from "gatsby"
import { SerializedStyles } from "@emotion/utils"

interface Props {
	slug: string
	heroImage: string
	description: string
	title: string
	tags: {
		label: string
		value: string
		color: string
		textColor: string
	}[]
	authorRef: IAuthorRef
	customStyle?: SerializedStyles[]
}

export interface IAuthorRef {
	firstName: string
	lastName: string
	slug: string
	title: string
	picture: any
}

const ArticleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 0 60px;

	max-width: 100%;
	img {
		max-width: 100%;
	}
	${ButtonBase} {
		width: initial;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: calc(100% / 2 - 24px);
		max-width: calc(100% / 2 - 24px);
		margin: 12px 12px 60px;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		flex-basis: calc(100% / 3 - 24px);
		max-width: calc(100% / 3 - 24px);
	}
	${ButtonBase} {
		width: initial;
	}
	&:hover {
		${ButtonBase} {
			text-decoration: underline;
		}
	}
`

const LinkStyles = css`
	color: var(--text-color);
	text-decoration: none;
`

export const ArticleTitle = styled.h3`
	font-size: 2rem;
	line-height: 2.8rem;
	font-weight: 800;
	margin: 0 0 10px;
`

export const ArticleDescription = styled.p`
	margin: 0 0 10px;
	font-size: 1.6rem;
	line-height: 2.4rem;
`

const ArticleTags = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: ${theme.spacing(1.5)};
`

const MediaContainer = styled(Link)`
	margin: 0 0 ${theme.spacing(2.6)};
	height: 270px;
	display: flex;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
`

const Author = styled.a`
	width: 100%;
	min-height: 60px;
	display: flex;
	align-items: center;
`

const AuthorImage = styled.div`
	height: 60px;
	width: 60px;
	border-radius: 50%;
	margin-right: ${theme.spacing(2)};
`

const AuthorText = styled.div`
	> * {
		margin-bottom: 0;
	}
`

const AuthorName = styled.h4`
	font-size: 1.6rem;
	line-height: 1.6rem;
	margin-bottom: ${theme.spacing(1)};
	font-weight: 800;
`

const AuthorRole = styled.p`
	font-size: 1.6rem;
	line-height: 1.6rem;
`

const customFilterStyles = css`
	padding: 5px 20px !important;
	margin: 0 ${theme.spacing(2)} ${theme.spacing(1)} 0 !important;
	height: fit-content !important;
`

const handleClickFilter = (slug: string) => navigate(`/articles/?tag=${slug}`)

const ArticleCard: React.FC<Props> = ({
	slug,
	description,
	heroImage,
	title,
	tags,
	authorRef,
	customStyle = [css``],
}) => {
	return (
		<ArticleContainer css={[...customStyle]}>
			<>
				<div>
					<MediaContainer css={LinkStyles} to={`/post/${slug}/`}>
						<Media alt={title} objectFit="cover" {...heroImage} />
					</MediaContainer>
					<ArticleTags>
						{tags.slice(0, 2).map(tag => {
							const { value, color, textColor, label } = tag
							return (
								<Filter
									key={value}
									css={[filterStyles, customFilterStyles]}
									color={color}
									textColor={textColor}
									onClick={() => handleClickFilter(value)}
								>
									{label}
								</Filter>
							)
						})}
					</ArticleTags>
					<Link css={LinkStyles} to={`/post/${slug}/`}>
						<ArticleTitle>{title}</ArticleTitle>
						<ArticleDescription>{description}</ArticleDescription>
					</Link>
				</div>
			</>
			{authorRef && (
				<Author href={`/author/${authorRef.slug}/`} css={LinkStyles}>
					{authorRef.picture && (
						<AuthorImage>
							<Media {...authorRef.picture} />
						</AuthorImage>
					)}
					<AuthorText>
						<AuthorName>
							{authorRef.firstName} {authorRef.lastName}
						</AuthorName>
						{authorRef.title && <AuthorRole>{authorRef.title}</AuthorRole>}
					</AuthorText>
				</Author>
			)}
		</ArticleContainer>
	)
}

export default React.memo(ArticleCard)
