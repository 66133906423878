import * as yup from 'yup'
import { IJsonContentfulField } from 'common/types'

interface ICardTextContent {
  title: string;
  description: string;
  hash: string;
  id: string;
}

const alternativeContentSchema = yup.object().shape({
	contentOn: yup.array().of(yup.object().shape({
		hash: yup.string(),
		title: yup.string(),
		description: yup.string(),
	}))
})

const getTextContent = (
	hash: string,
	alternativeContent: IJsonContentfulField,
	defaultTitle: string,
	defaultDescription: string,
): ICardTextContent => {
	if (hash && hash.length && alternativeContent) {
		try {
			const result = alternativeContentSchema.validateSync(alternativeContent.internal.content)

			const match = result.contentOn.find(r => r.hash === hash)
			if (match) {
				return {
					title: match.title,
					description: match.description,
					hash,
					id: hash.slice(1),
				}
			}
		} catch (err) {
			console.error(err)
		}
	}

	return {
		title: defaultTitle,
		description: defaultDescription,
		hash,
		id: hash.slice(1),
	}
}

export default getTextContent