interface EventProps {
    eventAction: string;
    eventLabel?: string;
    eventValue?: string | number;
}

const sendEvent = ( evtName: string, {  eventAction, eventLabel, eventValue }: EventProps ) => {

	const { ga } = (window as any)
	if ( ga ) {
		ga('send', {
			hitType: 'event',
			eventCategory: evtName,
			eventAction,
			eventLabel,
			eventValue,
		})
          
	}
}

const freeEmailBlocked = ( formId: string ) =>
	sendEvent( 'Freemail blocked', {
		eventAction: formId,
		eventLabel: formId,
	} )

const Analytics = {
	sendEvent,
	freeEmailBlocked,
}

export default Analytics