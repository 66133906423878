import { css } from "@emotion/react"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import * as React from "react"

const imageStyles = css`
	margin: 6.4rem auto;
	img {
		margin: 0;
	}
`

const sizes = [200, 400, 600, 800]

const mediaSizes = `(max-width: 800px) 100vw, 800px`

const ArticleImage = ({ alt, src: rawSrc }) => {
	// rmeove query params
	const src = React.useMemo( () => rawSrc.split('?')[0], [rawSrc] )

	const [width, setWidth] = React.useState<number>()
	const [aspectRatio, setAspectRatio] = React.useState<number>()

	const computedSizes = React.useMemo(() => {
		return sizes.concat(width).filter(size => size <= width)
	}, [width])

	const srcSet = React.useMemo(() => {
		return computedSizes.map(size => `${src}?w=${size}&q=90&fm=jpg ${size}w`).join(",")
	}, [src, width])

	const srcSetWebp = React.useMemo(() => {
		return computedSizes.map(size => `${src}?w=${size}&q=90&fm=webp ${size}w`).join(",")
	}, [src, width])

	React.useEffect(() => {
		const img = new Image()
		img.onload = () => {
			setAspectRatio(img.width / img.height)
			setWidth(img.width)
		}
		img.onerror = () => {
			setAspectRatio(1)
		}
		img.src = `${src}?w=800&fm=jpg&q=1`
	}, [])

	if (aspectRatio && width) {
		return (
			<GatsbyImage
				fluid={{
					srcSet,
					sizes: mediaSizes,
					src: `${src}?w=800&q=90&fm=jpg`,
					aspectRatio,
					srcSetWebp,
				}}
				alt={alt}
				loading="lazy"
				css={[
					imageStyles,
					css`
						max-width: ${width}px;
					`,
				]}
				imgStyle={{
					objectFit: "contain",
				}}
			/>
		)
	}
	return null
}

export const query = graphql`
	fragment ArticleImage on ContentfulAsset {
		__typename
		id
		description
		title
		file {
			contentType
			url
			fileName
			details {
				image {
					width
					height
				}
			}
		}
		fluid(maxWidth: 800, quality: 90) {
			...MediaFluid
		}
	}
`

export default React.memo(ArticleImage)
