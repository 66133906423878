import * as React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Media from "../Media"
import Container from "../container"
import Button, { ButtonBase } from "../button"
import { H2, P } from "../headings"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import Card from "./Card/Card"
import { ISectionProps, IThemeValue, ITitleSizeValue } from "./section.model"
import { getColorScheme } from "../../common/colorScheme"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { useSectionTheme } from "./SectionThemeContext"
import { elementData } from "./utils"

const textAndMediaContainerStyles = css`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const containerStyles = css`
	padding-bottom: 56px;
`

const coverStyles = css`
	display: flex;
	justify-content: center;
	content-visibility: initial;
	align-items: center;
	flex-direction: column;
`

const getBannerTextContainerStyles = (titleSize: ITitleSizeValue) => css`
	text-align: center;
	margin: 0 0 3.2rem;
	padding: 87px 1rem 0;
	align-self: center;
	${mediaBreakpoint(BreakPoints.SM)} {
		margin: 0 0 64px;
		width: 50%;
		margin: 0;
		padding: ${titleSize && titleSize === "large" ? "118px 2rem 0" : "8rem 2rem 0"};
	}
	&:empty {
		display: none;
	}
`

const bannerMediaStyles = css`
	width: 100%;
	max-width: 100%;
	${mediaBreakpoint(BreakPoints.SM)} {
		max-width: 66%;
		width: 66%;
	}
`

const buttonsContainerStyles = css`
	margin: 32px 0 0;
	&:empty {
		display: none;
	}
	${ButtonBase} {
		width: 100%;
		margin: 0 0 12px;
		${mediaBreakpoint(BreakPoints.SM)} {
			width: initial;
			margin: 0;
		}
	}
`
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8rem;
	&:empty {
		display: none;
	}
`

const cardsContainerStyles = css`
	padding-top: 0;
	padding-bottom: 0rem;
	display: flex;
	flex-direction: column;
	&:empty {
		display: none;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		margin-top: 2rem;
		padding-bottom: 5rem;
		flex-direction: row;
		justify-content: center;
	}
`

const singleCardStyles = css`
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: calc(100% / 3 - 60px);
		max-width: calc(100% / 3 - 60px);
	}
`

const buttonStyles = css`
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;
`

const headingStyles = css`
	margin: 0;
`

const descriptionStyles = css`
	margin: 16px 0 0;
`

const getThemeStyles = (theme: IThemeValue) => {
	switch (theme) {
	case "white-text":
		return css`
				color: white;
			`
	default:
		return css`
				color: var(--text-color);
			`
	}
}

const BannerCenter: React.FC<ISectionProps> = ({
	buttons,
	cards,
	colorScheme,
	description,
	media,
	title,
	titleLevel,
	children,
	customStyle,
	anchor,
	additionalText
}) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()

	const hasCards = () => cards && cards.length
	const hasButtons = () => buttons && buttons.length

	return (
		<section id={anchor} css={[customStyle, getThemeStyles(theme)]} data-element={elementData.section}>
			<div css={[coverStyles, getColorScheme(colorScheme)]} data-element={elementData.cover}>
				<Container css={containerStyles} data-element={elementData.container}>
					<div css={textAndMediaContainerStyles} data-element={elementData.textAndMediaContainer}>
						<div css={getBannerTextContainerStyles(titleSize)} data-element={elementData.bannerTextContainer}>
							{title ? (
								<H2 css={[getSizeStyles(titleSize), headingStyles]} sectionTheme={theme} as={titleLevel || "h2"} data-element="title">
									{title}
								</H2>
							) : null}
							{description && description.length ? (
								<P sectionTheme={theme} css={descriptionStyles}>
									{description}
								</P>
							) : null}
							{additionalText && (
								<Container data-element="additional-text-container">
									<P
										sectionTheme={theme}
										dangerouslySetInnerHTML={{ __html: additionalText?.childMarkdownRemark.html }}
									/>
								</Container>
							)}
							{hasButtons && !hasCards ? (
								<div css={buttonsContainerStyles}>
									{(buttons || []).map(button => {
										const { id } = button
										return (
											<div key={id} css={buttonStyles}>
												<Button {...button} />
											</div>
										)
									})}
								</div>
							) : null}
						</div>
						{media && (
							<div css={bannerMediaStyles}>
								<Media {...media} />
							</div>
						)}
					</div>
					{hasCards ? (
						<div css={cardsContainerStyles}>
							{(cards || []).map(({ id, ...rest }) => (
								<Card key={id} id={id} cardCss={singleCardStyles} {...rest} />
							))}
						</div>
					) : null}
					{hasCards && hasButtons ? (
						<ButtonContainer>
							{(buttons || []).map(button => {
								const { id } = button
								return (
									<div key={id} css={buttonStyles}>
										<Button {...button} />
									</div>
								)
							})}
						</ButtonContainer>
					) : null}
					{children}
				</Container>
			</div>
		</section>
	)
}

export default React.memo(BannerCenter)
