import { css } from '@emotion/react'
import { BreakPoints, mediaBreakpoint } from 'settings/breakpoints'

export const hideOnMobile = css`
  @media screen and (max-width: ${BreakPoints.SM - 0.5}px) {
    display: none;
  }
`

export const showOnMobile = css`
  ${mediaBreakpoint(BreakPoints.SM)} {
    display: none;
  }
`
