/* eslint-disable react/button-has-type */
import * as React from "react"
import { theme } from "common/colorScheme"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import useRect from "utils/useRect"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import Media, { MediaProps } from "components/Media"

export interface BrandLogosProps {
	images: MediaProps[]
	speed?: number
}

interface IBrandLogosScrollableProps {
	scrolled: number
	margin: number
}
const brandLogosContainer = css`
	width: 100%;
	overflow: hidden;
	margin-top: ${theme.spacing(4)};

	${mediaBreakpoint(BreakPoints.SM)} {
		margin-top: ${theme.spacing(7)};
	}
`

const BrandLogosScrollable = styled.div<IBrandLogosScrollableProps>`
	height: auto;
	max-height: 250px;
	width: max-content;
	display: flex;
	transition: transform 10s linear;
	transform: ${({ scrolled }) => `translateX(-${scrolled}px)`};
	padding-left: ${({ margin }) => `${margin}px`};
	margin-left: -500px;
	${mediaBreakpoint(BreakPoints.SM)} {
		max-height: 450px;
	}
`

const logoStyles = css`
	padding: ${theme.spacing(0, 3)};
	margin: ${theme.spacing(0, 2)};
`

const multiplyArrayItems = (arr: any[], times: number) => {
	const newArr = new Array(times).fill(arr)
	// Flatten the Array
	return Array.prototype.concat.apply([], newArr)
}

const BrandLogos: React.FC<BrandLogosProps> = ({ images, speed }) => {
	const [halfWayTrigger, setHalfWayTrigger] = React.useState<number>(0)
	const [items, setItems] = React.useState<MediaProps[] | []>(images)
	const [logosWidth, setLogosWidth] = React.useState<number>(0)
	const [scrolled, setScrolled] = React.useState<number>(0)
	const [margin, setMargin] = React.useState<number>(0)

	const brandLogosContainerRef = React.useRef(null)
	const brandLogosRef = React.useRef(null)
	const { width: containerWidth } = useRect(brandLogosContainerRef)
	const { width: totalLogosWidth } = useRect(brandLogosRef)

	const scrollSpeed = speed ?? 500

	React.useEffect(() => {
		const interval = window.setInterval(() => {
			setScrolled(prevScrolled => prevScrolled + scrollSpeed)
		}, 10000)

		setScrolled(scrollSpeed)

		return () => clearInterval(interval)
	}, [])

	React.useEffect(() => {
		if (scrolled > 0 && Math.abs(margin - scrolled) >= halfWayTrigger) setMargin(margin + halfWayTrigger)
	}, [scrolled, halfWayTrigger, logosWidth])

	React.useEffect(() => {
		if (items.length === images.length) setLogosWidth(totalLogosWidth)

		if (containerWidth > 0 && logosWidth > 0) {
			const factor = Math.ceil(containerWidth / logosWidth)
			const multiplyFactor = factor % 2 === 0 ? factor : factor + 1
			setHalfWayTrigger(logosWidth * multiplyFactor)

			const newItems = multiplyArrayItems(images, multiplyFactor * 2)
			setItems(newItems)
		}
	}, [logosWidth, totalLogosWidth, containerWidth])

	return items.length > 0 ? (
		<div css={brandLogosContainer} ref={brandLogosContainerRef} data-element="logos">
			<BrandLogosScrollable scrolled={scrolled} margin={margin} ref={brandLogosRef}>
				{items.map((item: MediaProps, i) => {
					const { id } = item

					// eslint-disable-next-line react/no-array-index-key
					return <Media css={logoStyles} key={`${id}${i}`} {...item} />
				})}
			</BrandLogosScrollable>
		</div>
	) : null
}

export default BrandLogos
