import * as React from "react"
import { graphql } from "gatsby"
import { SpanH3, P } from "components/headings"
import { css } from "@emotion/react"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { IColorScheme, getColorScheme, getWhiteText, theme } from "common/colorScheme"
import { elementData, getCustomStyle, getHeadingAlignStyles } from "components/sections/utils"
import { HeadingAlign, IJsonContentfulField } from "common/types"
import styled from "@emotion/styled"
import { EntryAnimations } from "components/ScrollAnimation"
import { getSizeStyles } from "components/sections/TitleSizeContext"
import { ITitleSizeValue } from "components/sections/section.model"
import Container from "../container"
import AlternatingCard, { AlternatingCardProps } from "./AlternatingCard"

interface Props {
	cards: AlternatingCardProps[]
	entryAnimation: EntryAnimations
	heading?: string
	inverse: boolean
	colorScheme?: IColorScheme
	whiteText?: boolean
	customSpacing?: IJsonContentfulField
	headingAlign: HeadingAlign | null
	anchor?: string
	subheader: string
	titleSize: ITitleSizeValue | null
}

const textContainerStyles = css`
	width: 100%;
	margin: 0;
	padding: 87px 0 0;
	align-self: center;
	margin-bottom: -30px;
	text-align: left;
	${mediaBreakpoint(BreakPoints.SM)} {
		text-align: center;
		width: 75%;
		padding: 10rem 0 0;
	}
`

const HeadingContainer = styled.div`
	display: flex;
	z-index: 1;
`

const SubheaderContainer = styled.div`
	padding-top: 40px;
`
const containerStyles = css`
	display: flex;
	flex-direction: column;
`

const coverStyles = css`
	content-visibility: initial;
	padding-bottom: ${theme.spacing(7.5)};
	${mediaBreakpoint(BreakPoints.LG)} {
		padding-bottom: ${theme.spacing(2)};
	}
`

const cardsContainer = css`
	${mediaBreakpoint(BreakPoints.SM)} {
		&:last-child {
			margin: 0 0 80px;
		}
	}
`

const AlternatingCards: React.FC<Props> = ({
	cards,
	heading,
	entryAnimation,
	inverse,
	colorScheme,
	whiteText,
	customSpacing,
	headingAlign,
	anchor,
	subheader,
	titleSize
}) => {
	const customStyle = React.useMemo(() => getCustomStyle(customSpacing), [customSpacing])

	return (
		<section id={anchor} css={[getColorScheme(colorScheme), coverStyles, customStyle]}>
			<Container css={[containerStyles]}>
				<HeadingContainer css={getHeadingAlignStyles(headingAlign)}>
					{heading ? (
						<div css={[textContainerStyles, getWhiteText(whiteText)]} data-element={elementData.bannerTextContainer}>
							<SpanH3 css={getSizeStyles(titleSize)}>{heading}</SpanH3>
						</div>
					) : null}
				</HeadingContainer>
				{subheader? <SubheaderContainer><P>{subheader}</P></SubheaderContainer> : null}
				<div css={cardsContainer}>
					{cards.map((card, index) => {
						const { id } = card
						return (
							<AlternatingCard
								key={id}
								even={inverse ? index % 2 !== 0 : index % 2 === 0}
								entryAnimation={entryAnimation}
								{...card}
							/>
						)
					})}
				</div>
			</Container>
		</section>
	)
}

export const query = graphql`
	fragment AlternatingCards on ContentfulAlternatingCards {
		__typename
		anchor
		heading
		headingAlign
		entryAnimation
		subheader
		id
		titleSize
		inverse
		cards {
			...AlternatingCard
		}
		colorScheme {
			...ColorScheme
		}
		whiteText
		customSpacing {
			internal {
				content
			}
		}
	}
`

export default React.memo(AlternatingCards)
