/* eslint-disable consistent-return */
import { RefObject, useEffect } from "react"

const useHorizontalSwipe = (
	ref: RefObject<any>,
	swipeLeftCallback = () => null,
	swipeRightCallback = () => null,
	minDistance = 100
) => {
	useEffect(() => {
		const node = ref?.current
		if (node) {
			let startPosition: number

			const handleStartTouch = (e: { preventDefault: () => void; changedTouches: { screenX: number }[] }) => {
				startPosition = e.changedTouches[0].screenX
			}
			const handleEndTouch = (e: { preventDefault: () => void; changedTouches: { screenX: any }[] }) => {
				const endPosition = e.changedTouches[0].screenX
				const touchMoveDifference = startPosition - endPosition

				if (Math.abs(touchMoveDifference) > minDistance) {
					if (touchMoveDifference > 0) {
						swipeRightCallback()
					} else {
						swipeLeftCallback()
					}
				}
			}

			node.addEventListener("touchstart", handleStartTouch, { passive: true })
			node.addEventListener("touchend", handleEndTouch, { passive: true })

			return () => {
				node.removeEventListener("touchstart", handleStartTouch)
				node.removeEventListener("touchend", handleEndTouch)
			}
		}
	}, [swipeRightCallback, swipeLeftCallback, minDistance, ref])
}

export default useHorizontalSwipe
