/* eslint-disable react/no-unknown-property */
import * as React from "react"
import { graphql } from "gatsby"
import { css, SerializedStyles } from "@emotion/react"
import styled from "@emotion/styled"
import Media from "components/Media"
import { richTextFromMarkdown } from "@contentful/rich-text-from-markdown"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { elementData, getCustomStyle, formatMultilineText } from "components/sections/utils"
import { getSizeStyles, TitleSizeContext } from "components/sections/TitleSizeContext"
import { ITitleSizeValue } from "components/sections/section.model"
import { getWhiteText, theme } from "common/colorScheme"
import BackgroundAnimation from "components/BackgroundAnimation"
import ScrollAnimation, { EntryAnimations } from "components/ScrollAnimation"
import { IJsonContentfulField } from "common/types"
import Card, {CardProps} from 'components/sections/Card/Card'
import { RichTextOptions, ImageToRichText } from "../../utils/richText"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import Category, { CategoryProps } from "../Category"
import { P, SpanH4 } from "../headings"
import Button, { ButtonProps } from "../button"

type AlignType = "center" | "end"

export interface AlternatingCardProps {
	id: string
	heading: string
	entryAnimation: EntryAnimations
	description: {
		description: string
	} | null
	headingLong: {
		headingLong: string
	} | null
	media: any
	buttons: ButtonProps[]
	category: CategoryProps | null
	even: boolean
	topContentImage?: any
	topContentText?: string
	bottomContent: {
		bottomContent: string
	} | null
	align: AlignType
	whiteText?: boolean
	backgroundGradientsTopLeft?: any
	backgroundGradientsTopRight?: any
	backgroundGradientsBottomLeft?: any
	backgroundGradientsBottomRight?: any
	customStyles?: SerializedStyles
	buttonsAtTheBottom?: boolean
	customSpacing?: IJsonContentfulField
	bulletPoints?: CardProps[]
	titleSize: ITitleSizeValue | null
}

interface IMediaContainerProps {
	even: boolean
}

interface IStyledContainerProps {
	customStyles?: any
	align: AlignType
}

const MediaContainer = styled.div<IMediaContainerProps>`
	display: none;
	order: 0;
	flex-basis: calc(50% - 16px);
	align-self: center;

	${mediaBreakpoint(BreakPoints.SM)} {
		display: block;
		font-size: 1.6rem;
		line-height: 2.4rem;
		max-width: 556px;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		margin-left: ${({ even }) => (even ? theme.spacing(2) : "0")};
		margin-right: ${({ even }) => (!even ? theme.spacing(2) : "0")};
	}
`

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	order: 1;
	flex-basis: 100%;
	max-width: 100%;
	z-index: 1;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: calc(50% - 16px);
		max-width: 486px;
	}
`

const StyledContainer = styled.div<IStyledContainerProps>`
	display: flex;
	align-items: ${({ align }) => {
		switch (align) {
		case "center":
			return "center"
		case "end":
			return "flex-end"
		default:
			return "flex-start"
		}
	}};
	justify-content: space-between;
	align-items: center;
	padding: 4.2rem 0 0;
	margin: 0;
	flex-direction: column;
	${({ customStyles }) => customStyles}
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: 8.2rem 0 0;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		flex-direction: row;
		align-items: initial;
	}
	&[data-even="true"] {
		${MediaContainer} {
			order: 1;
		}
		${TextContainer} {
			order: 0;
		}
	}

	&[data-even="false"] {
		flex-direction: column-reverse;

		${mediaBreakpoint(BreakPoints.LG)} {
			flex-direction: row;
		}
	}
`

const AdditionlContentContainer = styled.div`
	border-top: 1px solid var(--separator-color);
	padding: 22px 0 0;
`

const mobileMediaStyles = css`
	display: block;
	margin: 24px 0;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: none;
	}
`

const buttonStyles = css`
	width: 100%;
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;
	display: flex;

	a {
		width: 100%;

		${mediaBreakpoint(BreakPoints.SM)} {
			width: initial;
		}
	}
`

const additionalTopContent = css`
	display: flex;
	align-items: center;
	margin-bottom: ${theme.spacing(2.6)};
	.gatsby-image-wrapper {
		margin-right: ${theme.spacing(3)};
	}

	span {
		font-size: 1.6rem;
		font-weight: 800;
	}
`
const BulletPointContainer = styled.div`
	[data-element="card-container"] {
		flex-direction: row;
		margin-left: 0;
	}
	[data-element="media-container"] {
		align-self: baseline;
	}
	p {
		text-align: left;
		margin-left:1rem;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		p {
			text-align: left;
			margin-left:0;
		}
	}

`

const BulletPointsWrapper = styled.div`
`

const AlternatingCard: React.FC<AlternatingCardProps> = ({
	heading,
	description,
	media,
	buttons,
	category,
	even,
	topContentImage,
	topContentText,
	bottomContent,
	entryAnimation,
	align,
	whiteText,
	backgroundGradientsTopLeft,
	backgroundGradientsTopRight,
	backgroundGradientsBottomLeft,
	backgroundGradientsBottomRight,
	customStyles,
	customSpacing,
	bulletPoints,
	headingLong,
	titleSize
}) => {
	const [AditionalContent, setAdditionalContent] = React.useState<React.ReactNode>()

	React.useEffect(() => {
		if (bottomContent) {
			richTextFromMarkdown(bottomContent.bottomContent, ImageToRichText).then(richText =>
				setAdditionalContent(documentToReactComponents(richText, RichTextOptions))
			)
		}
	}, [bottomContent])

	return (
		<TitleSizeContext.Provider value={titleSize}>
			<ScrollAnimation type={entryAnimation} alternate={even}>
				<div css={getCustomStyle(customSpacing)}>
					<StyledContainer
						customStyles={customStyles}
						data-even={even}
						data-element={elementData.cardContainer}
						align={align}
					>
						<TextContainer data-element= "alternating-cards-text" css={getWhiteText(whiteText)}>
							{category ? <Category {...category} /> : null}
							{topContentImage || topContentText ? (
								<div css={additionalTopContent}>
									<Media {...topContentImage} /> <span>{topContentText}</span>
								</div>
							) : null}
							{heading ? <SpanH4 css={getSizeStyles(titleSize)}>{heading}</SpanH4> : null }
							{headingLong ? <SpanH4> {formatMultilineText(headingLong.headingLong)}</SpanH4>: null }
							{description ? <P>{formatMultilineText(description.description)}</P> : null}
							{bulletPoints ?
								<BulletPointsWrapper>
									{(bulletPoints || []).map(card => {
										const { id } = card
										return (
											<BulletPointContainer key={id} >
												<Card {...card} />
											</BulletPointContainer>
										)
									})}
								</BulletPointsWrapper>	
								: null
							}
							{media ? (
								<div css={mobileMediaStyles}>
									<Media {...media} />
								</div>
							) : null}
							{(buttons || []).map(button => {
								const { id } = button
								return (
									<div key={id} css={buttonStyles}>
										<Button {...button} />
									</div>
								)
							})}
							{AditionalContent ? (
								<>
									<AdditionlContentContainer>{AditionalContent}</AdditionlContentContainer>
								</>
							) : null}
						</TextContainer>
						<MediaContainer data-element= "alternating-cards-media" even={even}>
							{media ? (
								<div style={{ position: "relative", maxWidth: "100%" }}>
									<Media {...media} />
									<BackgroundAnimation
										backgroundGradientsTopLeft={backgroundGradientsTopLeft}
										backgroundGradientsTopRight={backgroundGradientsTopRight}
										backgroundGradientsBottomLeft={backgroundGradientsBottomLeft}
										backgroundGradientsBottomRight={backgroundGradientsBottomRight}
									/>
								</div>
							) : null}{" "}
						</MediaContainer>
					</StyledContainer>
				</div>
			</ScrollAnimation>
		</TitleSizeContext.Provider>
	)
}

export const query = graphql`
	fragment AlternatingCard on ContentfulAlternatingCardsCard {
		__typename
		id
		heading
		headingLong {
			headingLong
		}
		titleSize
		description {
			description
		}
		media {
			...MediaOriginal
		}
		buttons {
			...Button
		}
		category {
			...Category
		}
		heading
		bulletPoints {
			...Card
		}
		topContentImage {
			fluid {
				...MediaFluid
			}
			file {
				contentType
				url
				details {
					image {
						height
						width
					}
				}
			}
		}
		topContentText
		bottomContent {
			bottomContent
		}
		align
		whiteText
		backgroundGradientsTopLeft {
			file {
				url
			}
		}
		backgroundGradientsTopRight {
			file {
				url
			}
		}
		backgroundGradientsBottomLeft {
			file {
				url
			}
		}
		backgroundGradientsBottomRight {
			file {
				url
			}
		}
		customSpacing {
			internal {
				content
			}
		}
	}
`

export default React.memo(AlternatingCard)
