import React from "react"
import { css } from "@emotion/react"
import { getColorScheme } from "common/colorScheme"
import styled from "@emotion/styled"
import Form from "components/Form"
import { H2, P } from "../headings"
import Card from "./Card/Card"
import Container from "../container"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { ISectionProps } from "./section.model"
import { useSectionTheme } from "./SectionThemeContext"
import Button, { ButtonBase } from "../button"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { elementData } from "./utils"

const containerStyles = css`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 36px;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
		padding: 80px 16px;
	}
`

const buttonsContainerStyles = css`
	${ButtonBase} {
		width: 100%;
		margin: 0 0 12px;
		${mediaBreakpoint(BreakPoints.SM)} {
			width: initial;
			margin: 0;
			min-width: 134px;
		}
	}
`

const buttonStyles = css`
	margin: 1.6rem 0;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: inline-block;
	}
`

const buttonsMobile = css`
	display: block;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: none;
	}
`


const CardsContainer = styled.div`
    width: 100%;
    margin-right: 6rem;
        ${mediaBreakpoint(BreakPoints.SM)} {
            width: 40%;
        }
`
const FormContainer = styled.div`
    width: 100%;
    ${mediaBreakpoint(BreakPoints.SM)} {
        width: 60%;
    }
`

const CardsLeft = ({
	title,
	titleLevel,
	description,
	buttons,
	cards,
	colorScheme,
	customStyle,
	newsletterFormPurple
}: ISectionProps) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()

	return (
		<div css={customStyle}>
			<section css={getColorScheme(colorScheme)} data-element={elementData.cover}>				
				<Container css={containerStyles} data-element={elementData.container}>
					<CardsContainer data-element="cards-container">								
						<H2 css={getSizeStyles(titleSize)} sectionTheme={theme} as={titleLevel || "h2"}>
							{(title)}
						</H2>
						{description && description.length && <P sectionTheme={theme}>{(description)}</P>}
						{cards.length ? (cards || []).map((card, index) => <Card key={`card${index + 1}`} {...card} />) : null}
						{buttons.length
							? (buttons || []).map(button => {
								const { id } = button
								return (
									<div key={id} css={[buttonsContainerStyles, buttonsMobile]}>
										<Button css={buttonStyles} {...button} />
									</div>
								)
						  })
							: null}						
					</CardsContainer>
					{ newsletterFormPurple ?
						<FormContainer data-element="form-container">
						 <Form {...newsletterFormPurple}/>
						</FormContainer> : null}
				</Container>
			</section>
		</div>
	)
}

export default React.memo(CardsLeft)
