import React from "react"
import styled from "@emotion/styled"
import { SearchIcon } from "components/icons"

const SearchInputWrapper = styled.div`
	position: relative;

	img {
		position: absolute;
		top: 50%;
		right: 17px;
		transform: translateY(-50%);
	}
`

const Input = styled.input`
	display: block;
	width: 100%;
	border: 1px solid rgba(51, 14, 112, 0.1);
	border-radius: 5px;
	font-size: 1.3rem;
	color: var(--main-color);
	line-height: 2em;
	font-weight: 800;
	padding: 10px 58px 10px 18px;
	margin-bottom: 4rem;
	text-transform: uppercase;
	back &:focus {
		border-color: var(--main-color);
	}
	&::placeholder {
		color: var(--main-color);
		opacity: 1;
	}
`

const SearchInput = ({ type, searchParam, onHandleSearch, placeholder }) => {
	return (
		<SearchInputWrapper key={searchParam}>
			<Input
				type={type}
				placeholder={placeholder}
				onChange={onHandleSearch}
				defaultValue={searchParam || ""}
				autoFocus={!!searchParam?.length}
			/>
			<img src={SearchIcon} alt="Magnyfing glass icon" height={30} width={30} />
		</SearchInputWrapper>
	)
}

export default SearchInput