/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React from 'react'
import { graphql } from 'gatsby'
import { SectionLayoutAlign } from 'components/sections/section.model'

import {
	alignContentFirsttStyles,
	alignMediaFirstStyles,
	getVariantStyles,
} from './styles'
import { IAccordionProps } from './shared'
import { AccordionSection } from './components'
import MobileTransparentVariant from './mobile_transparent_variant'
import DefaultVariant from './default_variant'

export const layoutAlignStyles = {
	[SectionLayoutAlign.ContentFirst]: alignContentFirsttStyles,
	[SectionLayoutAlign.MediaFirst]: alignMediaFirstStyles,
}

const Accordion: React.FC<IAccordionProps> = (props) => {
	const { items, layoutAlignment, variant } = props
	if (!items) return null
	return (
		<AccordionSection
			id="accordion-test-id"
			css={[layoutAlignStyles[layoutAlignment], getVariantStyles(variant)]}
		>
			<MobileTransparentVariant {...props} />
			<DefaultVariant {...props} />
		</AccordionSection>
	)
}

export const query = graphql`
  fragment Accordion on ContentfulAccordion {
    __typename
    id
    name
    layoutAlignment
    variant
    items {
      ...AccordionItem
    }
  }

  fragment AccordionItem on ContentfulAccordionItem {
    __typename
    id
    title
    description
    alternativeText {
			alternativeText
			childMarkdownRemark {
				html
			}
		}
    subItems {
      __typename
      id
      title
      description
      accordionItemButton {
        ...Button
      }
      accordionItemImage {
        ...Media450
      }
      accordionCard {
        ...Card
      }
    }
    accordionItemButton {
      ...Button
    }
    accordionItemImage {
      ...Media800
    }
    accordionCard {
      ...Card
    }
  }
`
export default Accordion
