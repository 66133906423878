import styled from "@emotion/styled"
import { getColorScheme, IColorScheme, theme, theme as themeCommon } from "common/colorScheme"
import { ContentfulTypes } from "common/types"
import Button, { ButtonBase } from "components/button"
import Container from "components/container"
import { H3, H4, P } from "components/headings"
import Media from "components/Media"
import * as React from "react"
import BrandImagesWithReviews from "components/BrandImagesWithReviews"
import BrandImagesNew from "components/BrandImagesNew"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import MediaSection from "components/sections/MediaSection"
import { css } from "@emotion/react"
import { PageReference } from "./types"
import { FeaturedStyle, IBrandImages } from "."

interface IFeaturedReferenceProps {
	title: string
	featuredSubtitle: string
	reference: PageReference
	buttonText: string
	featuredStyle?: FeaturedStyle
	featuredColorScheme?: IColorScheme
	embedYoutubeId:string
	brandImages: IBrandImages
	brandImagesScrolling: boolean
}

const Section = styled.section`
	background: #f9f9fb;
	padding: 60px 0;
`

export const SubTitle = styled.h2`
	font-weight: 800;
	text-transform: uppercase;
	font-size: 1.2em;
	color: ${themeCommon.color.cyan} !important;
	margin: 0;
	margin-bottom: ${themeCommon.spacing(2)};
`

const BrandBiteTitle = styled.h1`
	font-size: 2rem;
	line-height: 2.8rem;
	margin: 0 0 16px;
    font-weight: 800;
	color: ${theme.color.white};

	${mediaBreakpoint(BreakPoints.MD)} {
		font-size: 48px;
		line-height: 5.2rem;
	}
`

const ReferenceContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	margin: 40px 0 0;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
	}
`

const ReferenceMedia = styled.div`
	flex-basis: 100%;
	width: 100%;
	margin: 0 0 40px;
		img {
			border-radius:10px;
		}

	${mediaBreakpoint(BreakPoints.SM)} {
		margin: 0;
		flex-basis: calc(50% - 16px);
		max-width: 612px;
		width: 50%;
	}
`

const ReferenceDetails = styled.div`
	flex-basis: 100%;
	${ButtonBase} {
		width: initial;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		max-width: 486px;
		flex-basis: calc(50% - 16px);
	}
`
const ReferenceTitle = styled.h4`
	font-size: 2.4rem;
	line-height: 3rem;
	margin: 0 0 16px;
    font-weight: 800;
	color: ${theme.color.white};

	${mediaBreakpoint(BreakPoints.MD)} {
	font-size:  3.6rem;
	line-height: 1;
	}
`
const BrandBiteP = styled.p`
	font-size: 2rem;
	line-height: 2.8rem;
	margin: 0 0 32px;
	color: ${theme.color.white};
`
const ReferenceCategoryMediaContainer = styled.div`
	max-height: 23px;
	margin: 0 0 32px;
	filter: brightness(0);
`

const BrandImagesContainer = styled.div`
	-webkit-background-size: initial;
	background-size: initial;
	padding-top: 80px;
`

const FeaturedReference: React.FC<IFeaturedReferenceProps> = ({
	title,
	featuredSubtitle,
	buttonText,
	reference,
	featuredStyle,
	featuredColorScheme,
	embedYoutubeId,
	brandImages,
	brandImagesScrolling
}) => {
	if (!reference) return null

	const {
		heroImage,
		categoryImage,
		description,
		slug,
		longTitle: refLongTitle,
		title: refTitle,
		__typename,
	} = reference

	const referenceTitle = refLongTitle ? refLongTitle.title : refTitle
	const brandBite = featuredStyle === "brand-bites"

	const path = React.useMemo(() => {
		if (__typename === ContentfulTypes.BlogPost) {
			return `post/${slug}`
		}
		if (__typename === ContentfulTypes.KnowledgePost) {
			return `knowledge-center/${slug}`
		}

		return `${slug}`
	}, [__typename, slug])
	return (
		<Section data-element="featured-section" css={[getColorScheme(featuredColorScheme, { noImage: true })]}>
			<Container>
				{brandBite ? (
					<>
						<BrandBiteTitle data-element="brand-bites-title">{title}</BrandBiteTitle>
						<BrandBiteP>{featuredSubtitle}</BrandBiteP>
						<ReferenceContainer>
							{  heroImage && embedYoutubeId ? (
								<ReferenceMedia>
									<MediaSection
										showVideoControls
										videoAutoplay
										muted
										embedYoutubeId={embedYoutubeId}
										customStyle={css`
										[data-element="cover"] {
											padding: 0;
										}
									`}
									/>
								</ReferenceMedia>
							) : (
								<ReferenceMedia>									
									<Media
										imgStyle={{
											objectFit: "contain",
										}}
										{...heroImage}
									/>	
								</ReferenceMedia>
							)}							
							<ReferenceDetails>
								{categoryImage && (
									<ReferenceCategoryMediaContainer>
										<Media {...categoryImage} />
									</ReferenceCategoryMediaContainer>
								)}
								<SubTitle>Featured</SubTitle>
								<ReferenceTitle>{referenceTitle}</ReferenceTitle>
								{description ? <BrandBiteP>{description.description}</BrandBiteP> : null}
								<Button
									type="button"
									variant="primary-new"
									label={buttonText}
									link={
									{
										slug: path,
									} as any
									}
								/>
							</ReferenceDetails>
						</ReferenceContainer>
					</>
				) : (
					<>
						<H3>{title}</H3>
						<ReferenceContainer>
							<ReferenceMedia>
								{heroImage ? (
									<Media
										imgStyle={{
											objectFit: "contain",
										}}
										{...heroImage}
									/>
								) : null}
							</ReferenceMedia>
							<ReferenceDetails>
								{categoryImage && (
									<ReferenceCategoryMediaContainer>
										<Media {...categoryImage} />
									</ReferenceCategoryMediaContainer>
								)}
								<H4>{referenceTitle}</H4>
								{description ? <P>{description.description}</P> : null}
								<Button
									type="button"
									variant="secondary"
									label={buttonText}
									link={
										{
											slug: path,
										} as any
									}
								/>
							</ReferenceDetails>
						</ReferenceContainer>
					</>
				)}
			</Container>
			{brandImages && brandImagesScrolling ?(
				<BrandImagesContainer> 
					<BrandImagesWithReviews compact transparent images={brandImages.logos} hideScrollingImages={false}/>
				</BrandImagesContainer>
			) : (
				brandImages && <BrandImagesNew brandImages={brandImages} />)} 
		</Section>
	)
}

export default React.memo(FeaturedReference)
