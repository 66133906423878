/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, { useState, useRef } from 'react'
import Button from 'components/button'
import Media from 'components/Media'
import { SpanH2 } from 'components/headings'
import Card from 'components/sections/Card/Card'
import { BurgerMenuIcon, ChevronIcon, CloseIcon } from 'components/icons'
import {
	AccordionButton,
	Content,
	Description,
	HeaderContainer,
	HeaderText,
	Item,
	Image,
	MenuButton,
	MenuPanel,
	OnlyMobileImages,
	Panel,
	Title,
	ItemIcon,
	ItemContainer,
} from './components'
import {
	IAccordionItemProps,
	IAccordionProps,
	IAccordionVariant,
} from './shared'
import { getActiveClass, isItemActive, isPanelActive } from './utils'

const MobileTransparentVariant: React.FC<IAccordionProps> = ({
	items = [],
	variant,
}) => {
	const [activeProperties, setActivePropeties] = useState([0, 0])
	const onTitleClick = (index, level) => {
		if (level === null) {
			setActivePropeties([index, 0])
		} else {
			setActivePropeties([index, level])
		}
	}
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

	const contentEl = useRef()

	const MobilePanelHeader = () => (
		<HeaderContainer>
			<HeaderText>FILTER</HeaderText>
			<MenuButton
				show={!mobileMenuOpen}
				icon={BurgerMenuIcon}
				onClick={() => setMobileMenuOpen(true)}
			/>
			<MenuButton
				show={mobileMenuOpen}
				icon={CloseIcon}
				onClick={() => setMobileMenuOpen(false)}
			/>
		</HeaderContainer>
	)

	const getMobileItem = ({
		item,
		parentIndex,
		subIndex = null,
	}: {
    item: IAccordionItemProps;
    parentIndex: number;
    subIndex?: number;
  }) => {
		const [activeParentIndex, activeSubIndex] = activeProperties
		const active = isItemActive({
			parentIndex,
			subIndex,
			activeParentIndex,
			activeSubIndex,
		})
		const { id, subItems } = item

		const itemClass = subIndex === null ? 'parent' : 'child'
		const itemExpandingClass = subItems && subItems.length ? 'expanding' : ''
		const itemClassName = `${getActiveClass(active)} ${itemExpandingClass}`

		return (
			<>
				<div key={id}>
					<Title
						onClick={() => onTitleClick(parentIndex, subIndex)}
						className={`${getActiveClass(active)} ${itemClass}`}
					>
						<SpanH2>{item.title}</SpanH2>
						<ItemIcon
							className={itemClassName}
							src={ChevronIcon}
							alt="chevron"
						/>
					</Title>
				</div>
				<ItemContainer className={itemClassName}>
					{(subItems || []).map((subItem, index) =>
						getMobileItem({ item: subItem, parentIndex, subIndex: index })
					)}
				</ItemContainer>
			</>
		)
	}

	const getMobilePanel = (activeItem) => (
		<Panel className="active" ref={contentEl}>
			{activeItem.description ? (
				<Description>
					<p>{activeItem.description}</p>
				</Description>
			) : null}
			{activeItem.accordionItemButton ? (
				<AccordionButton>
					<Button {...activeItem.accordionItemButton} />
				</AccordionButton>
			) : null}
			<OnlyMobileImages>
				<Image className="active">
					<Media {...activeItem.accordionItemImage} />
					<Card {...activeItem.accordionCard} />
				</Image>
			</OnlyMobileImages>
		</Panel>
	)

	const getActiveItem = (allItems: IAccordionItemProps[]) => {
		const [activeParentIndex, activeSubIndex] = activeProperties
		let activeItem = null
		allItems.forEach((item, parentIndex) => {
			const { subItems } = item
			const active = isPanelActive({
				parentIndex,
				subIndex: null,
				activeParentIndex,
				activeSubIndex,
				subItems,
			})
			if (active) {
				activeItem = item
			}
			if (subItems) {
				subItems.forEach((subItem, subIndex) => {
					const subActive = isPanelActive({
						parentIndex,
						subIndex,
						activeParentIndex,
						activeSubIndex,
						subItems: null,
					})
					if (subActive) {
						activeItem = subItem
					}
				})
			}
		})
		return activeItem
	}

	const activeItem = getActiveItem(items)

	return (
		<Item hideDesktop hideMobile={variant !== IAccordionVariant.Transparent}>
			<Content>
				<MobilePanelHeader />
				<MenuPanel className={mobileMenuOpen ? 'active' : ''}>
					{items.map((item, index) =>
						getMobileItem({ item, parentIndex: index })
					)}
				</MenuPanel>
				{activeItem ? getMobilePanel(activeItem) : null}
			</Content>
		</Item>
	)
}

export default MobileTransparentVariant
