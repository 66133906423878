/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable react/no-unknown-property */
import * as React from "react"
import Container from "components/container"
import styled from "@emotion/styled"
import { getColorScheme, theme as themeCommon } from "common/colorScheme"
import { css } from "@emotion/react"
import { useEffect, useState } from "react"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { H2, H4, P } from "../headings"
import Button, { ButtonBase } from "../button"
import {  ISectionProps, SectionLayoutAlign } from "./section.model"
import { elementData, formatMultilineText } from "./utils"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { useSectionTheme } from "./SectionThemeContext"

const containerStyles = css`
	display: block;
	padding-top: 4.8rem;
	padding-bottom: 4.8rem;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding-top: 0;
		padding-bottom: 0;
	}
`

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;

	${ButtonBase} {
		width: initial;
		&[data-variant="hollow"] {
			width: initial;
		}
	}
`

const buttonStyles = css`
	margin-right: 2.4rem;
`

const Cover = styled.div`
	display: flex;
	content-visibility: initial;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: 60px 0;
		align-items: center;
	}
`

const TextContainer = styled.div`
	flex-basis: 50%;
`

const alignContentFirsttStyles = css`
	${mediaBreakpoint(BreakPoints.SM)} {
		${TextContainer} {
			order: 1;
		}
	}
`

const alignMediaFirstStyles = css`
	${TextContainer} {
		order: 2;
	}
`
const TyperWriter = styled.h2`
	color: #9450FF;
`
const layoutAlignStyles = {
	[SectionLayoutAlign.ContentFirst]: alignContentFirsttStyles,
	[SectionLayoutAlign.MediaFirst]: alignMediaFirstStyles,
}

interface Props extends ISectionProps {}

const TitleTextHorizontal: React.FC<Props> = ({
	colorScheme,
	title,
	titleLevel,
	description,
	buttons,
	layoutAlignment,
	customStyle,
	typerwriterContent,
	subTitle
}) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()

	const [index, setIndex] = useState(0)
	const [subIndex, setSubIndex] = useState(0)
	const [reverse, setReverse] = useState(false)
	const [blink, setBlink] = useState(true)

	// Typewritter 
	useEffect(() => {
		if (index === typerwriterContent.length - 1 && subIndex === typerwriterContent[index].length) {
		  return
		}
	
		if (
		  subIndex === typerwriterContent[index].length + 1 && 
		  index !== typerwriterContent.length - 1 && 
		  !reverse 
		) {
		  setReverse(true)
		  return
		}
	
		if (subIndex === 0 && reverse) {
		  setReverse(false)
		  setIndex((prev) => prev + 1)
		  return
		}
	
		const timeout = setTimeout(() => {
		  setSubIndex((prev) => prev + (reverse ? -1 : 1))
		}, Math.max(reverse ? 75 : subIndex === typerwriterContent[index].length ? 1000 :
			100, parseInt(Math.random() * 150)))
	
		return () => clearTimeout(timeout)
	  }, [subIndex, index, reverse])
	
	  // blinker
	  useEffect(() => {
		const timeout2 = setTimeout(() => {
		  setBlink((prev) => !prev)
		}, 500)
		return () => clearTimeout(timeout2)
	  }, [blink])
	
	return (
		<section css={customStyle}>
			<Cover css={getColorScheme(colorScheme)} data-element={elementData.cover}>
				<Container css={[containerStyles, layoutAlignStyles[layoutAlignment]]} data-element={elementData.container}>
					<TextContainer data-element={elementData.textContainer}>
						{subTitle && <P data-element="subtitle">{subTitle}</P>}
						{title && <H2 css={getSizeStyles(titleSize)} sectionTheme={theme} as={titleLevel || "h2"} >
							{formatMultilineText(title)}
						</H2> }
						{typerwriterContent ? 
							<TyperWriter css={getSizeStyles(titleSize)} as={titleLevel || "h2"} data-element="typewriter">
								{`${typerwriterContent[index].substring(0, subIndex)}${blink ? "|" : ""}`}
							</TyperWriter> : null}
						{buttons && 
						<ButtonsContainer>
							{(buttons || []).map(button => {
								const { id } = button

								return (
									<div key={id} css={buttonStyles}>
										<Button {...button} />
									</div>
								)
							})}
						</ButtonsContainer>
						}
					</TextContainer>
					<TextContainer data-element={elementData.textContainer}>
						{ description && <P sectionTheme={theme} >{formatMultilineText(description)}</P> }
					</TextContainer>
				</Container>
			</Cover>
		</section>
	)
}

export default React.memo(TitleTextHorizontal)
