import { FormFieldBase } from 'components/Form/FormField'
import { GATSBY_HUBSPOT_PORTAL_ID } from 'settings/env'
import {
	getCookie
} from 'utils/cookies'

const submitForm = ( formId: string, formData: Record<string, any> ) => {
	const timeStamp = new Date().getTime()
	const hubspotUserToken = getCookie( 'hubspotutk' )

	const fields = Object.entries(formData).reduce((acc, [name, value]) => {
		if(name !== 'termsAccepted') {
			return [...acc, {name, value}]
		}
		return acc
	}, [])

	return new Promise( ( resolve, reject ) => {
		let okay = true
		return fetch( `https://api.hsforms.com/submissions/v3/integration/submit/${ GATSBY_HUBSPOT_PORTAL_ID }/${ formId }`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify( {
				submittedAt: timeStamp, // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
				fields,
				context: {
					hutk: hubspotUserToken,
				},
				legalConsentOptions: {
					consent: { // Include this object when GDPR options are enabled
						consentToProcess: true,
						text: "I agree to allow Latana to store and process my personal data.",
						communications: [
							{
								value: true,
								subscriptionTypeId: 999,
								text: "I agree to receive marketing communications from Latana."
							}
						]
					}
				}
			} ),
		} )
			.then( resp => {
				okay = resp.ok
				return resp.json()
			})
			.then( data => okay ? resolve(data) : reject(data) )
	} )
}

interface HubspotError {
	message: string;
	errorType: string;
}

const InvalidEmailTypes = [
	'BLOCKED_EMAIL',
	'INVALID_EMAIL'
]

const getErrors = ( {errors}: {errors: HubspotError[]}, fields: FormFieldBase[] ) => {
	const emailError = errors.find( ( { errorType } ) => InvalidEmailTypes.includes( errorType ) )

	if ( emailError ) {
		return fields.reduce( (acc, field) => {
			if ( field.type === 'email' ) {
				acc[ field.fieldName ] = 'Please enter a valid business email.'
			}
			return acc
		}, {} as Record<string, string> )
	}
	return {}


}

const hubspot = {
	submitForm,
	getErrors,
}


export default hubspot
