/* eslint-disable no-nested-ternary */
import * as React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { IJsonContentfulField } from "common/types"
import { getCustomStyle } from "components/sections/utils"
import { theme } from "common/colorScheme"
import { IThemeValue } from "components/sections/section.model"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import { MediaProps } from "components/Media"
import { H3, P } from "../headings"
import Container from "../container"
import BrandLogos from "./BrandLogos"
import Reviews from "./Reviews"

export interface ReviewProps {
	caption: {
		caption: string
	}
	image: MediaProps
	logo: MediaProps
	reviewerName: string
	reviewerTitle: string
	id: string
	kicker: string
	link: {
		slug: string
	}
}

export interface IBrandImagesWithReviewsProps {
	images: MediaProps[]
	heading?: string
	reviews?: ReviewProps[]
	compact?: boolean
	whiteTheme?: boolean
	logosScrollingSpeed?: number
	reviewScrollInterval?: number
	transparent?: boolean
	subHeading?:string
	customSpacing?: IJsonContentfulField
	hideScrollingImages?: boolean
}

interface IBrandImagesWithReviewsContainerProps {
	whiteTheme?: boolean
	hidden?: boolean
	transparent?: boolean
}

export type IVariantValue = "full-width" | "compact" | "large"

export interface IImage {
	title: string
	file: {
		url: string
	}
	id: string
}

const BrandImagesWithReviewsContainer = styled.section<IBrandImagesWithReviewsContainerProps>`
	width: 100%;
	background-color: ${({ whiteTheme, transparent }) =>
		!transparent && whiteTheme ? theme.color.white : !transparent ? theme.color.black : "transparent"};
	padding: ${theme.spacing(10, 0)};
	display: ${({ hidden }) => (hidden ? "none" : "block")};

	${mediaBreakpoint(BreakPoints.SM)} {
		display: block;
	}
`

const header = css`
	text-align: center;
`

const BrandImagesWithReviews: React.FC<IBrandImagesWithReviewsProps> = ({
	heading,
	compact,
	whiteTheme,
	images,
	logosScrollingSpeed,
	reviewScrollInterval,
	reviews,
	transparent,
	subHeading,
	customSpacing,
	hideScrollingImages
}) => {
	const headingTheme: IThemeValue = whiteTheme ? "default" : "white-text"

	return (
		<BrandImagesWithReviewsContainer
			hidden={compact}
			whiteTheme={whiteTheme}
			data-element="brand-images"
			transparent={transparent}
		>
			<Container css={getCustomStyle(customSpacing)} data-element="text-container">
				{heading && (
					<div data-element="text-container-title" css={header}>
						<H3  sectionTheme={headingTheme}>{heading}</H3>
					</div>
				)}
				{subHeading && (
					<div  data-element="text-container-p" css={header}>
						<P sectionTheme={headingTheme}>{subHeading}</P>
					</div>
				)}
			</Container>
			{!hideScrollingImages? <BrandLogos css={getCustomStyle(customSpacing)} images={images } speed={logosScrollingSpeed}/> : null }
			{!compact && reviews && <Reviews reviews={reviews} whiteTheme={whiteTheme} interval={reviewScrollInterval} />}
		</BrandImagesWithReviewsContainer>
	)
}

export const query = graphql`
	fragment BrandImagesWithReviews on ContentfulBrandImagesWithReviews {
		__typename
		id
		heading
		subHeading
		whiteTheme
		compact,
		hideScrollingImages
		images {
			...Media750
		}
		customSpacing {
			internal {
				content
			}
		}
		logosScrollingSpeed
		reviewScrollInterval
		reviews {
			image {
				...Media400
			}
			reviewerName
			reviewerTitle
			caption {
				caption
			}
			logoBlack {
				...Media160
			}
			logoWhite {
				...Media160
			}
			link {
				slug
			}
			id
		}
	}
`

export default React.memo(BrandImagesWithReviews)
