/* eslint-disable react/destructuring-assignment */
import React from "react"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import styled from "@emotion/styled"
import ArticleImage from "components/ArticleImage"
import Media from "components/Media"
import { css } from "@emotion/react"
import Infographic from "components/Infographic"
import slugify from "./slugify"
import { useSectionTheme } from "../components/sections/SectionThemeContext"

const P = styled.p<{ theme: String }>`
	font-size: 1.6rem;
	line-height: 2.4rem;
	${({ theme }) => (theme === "white-text" ? "color: white;" : "color: var( --text-color );")}
	margin: 0 0 14px;
	a {
		color: inherit;
	}
`
const ThemedP = ({ children }: any) => {
	const theme = useSectionTheme()
	return <P theme={theme}>{children}</P>
}

const Q = styled(P)`
	opacity: 0.9;
	font-style: italic;
	> p {
		&:before {
			content: "“";
		}
		&:after {
			content: "”";
		}
	}
`

export const ImageToRichText = (markdown: any) => {
	if (markdown.type === "image") {
		return {
			nodeType: "custom-image",
			content: [],
			data: {
				src: markdown.url,
				alt: markdown.alt,
			},
		}
	}
	return undefined
}

const websiteUrl = 'https://latana.com'

export const RichTextOptions = {
	renderMark: {},
	renderNode: {
		[BLOCKS.HEADING_1]: (_node, children) => <h1 id={slugify(children[0])}>{children}</h1>,
		[BLOCKS.HEADING_2]: (_node, children) => <h2 id={slugify(children[0])}>{children}</h2>,
		[BLOCKS.HEADING_3]: (_node, children) => <h3 id={slugify(children[0])}>{children}</h3>,
		[BLOCKS.QUOTE]: (_node, children) => <Q>{children}</Q>,
		[BLOCKS.PARAGRAPH]: (_node, children) => <ThemedP>{children}</ThemedP>,
		"custom-image": node => {
			const { data } = node
			return <img {...data} alt="" />
		},
	},
}

const imageStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 6.4rem auto;
	width: 100%;
	img {
		margin: 0;
	}
	> div {
		width: 100%;
	}
`
const StyledTable = styled.table`
	display: block;
	margin: 3.2rem 0;
	font-size: 1.6rem;
	line-height: 1.5;
	overflow-x: auto;
	max-width: calc(100vw - 2rem);

	td {
		padding: 0.8rem 1.6rem 0.8rem 0;
		vertical-align: top;
	}
	tr:first-child {
		font-weight: bold;
	}
`

export const ArticleRichTextOptions = collection => ({
	renderMark: {},
	renderNode: {
		[INLINES.HYPERLINK]: (node) => {
			return <a href={node.data.uri} target={node.data.uri.startsWith(websiteUrl) ? '_self' : '_blank'} rel={node.data.uri.startsWith(websiteUrl) ? '' : 'noopener noreferrer'}>{node.content[0].value}</a>
		},
		"custom-image": node => {
			const { data } = node
			const contentful = collection[data.src]
			if (contentful) {
				const { fluid, file, title, description, id } = contentful
				return (
					<div css={imageStyles}>
						<Media id={id} fluid={fluid} file={file} alt={description || title} />
					</div>
				)
			}
			return <ArticleImage {...data} />
		},
		infographic: node => {
			const { data } = node
			const contentful = collection[data.src]

			if (contentful) {
				const { fluid, file, title, description, id } = contentful
				return <Infographic id={id} fluid={fluid} file={file} alt={description || title} />
			}
			return null
		},
		code: node => {
			const { data } = node
			return (
				// eslint-disable-next-line react/no-danger
				<pre dangerouslySetInnerHTML={{ __html: data.code }} />
			)
		},
		twitter: node => {
			const { data } = node
			return (
				// eslint-disable-next-line react/no-danger
				<div dangerouslySetInnerHTML={{ __html: data.code }} />
			)
		},
		instagram: node => {
			const { data } = node
			return (
				// eslint-disable-next-line react/no-danger
				<div className="instagram-container" dangerouslySetInnerHTML={{ __html: data.code }} />
			)
		},
		"custom-iframe": node => {
			const { data } = node
			return <iframe title="Custom Iframe" width="800" height="400" {...data} />
		},
		"custom-table": node => {
			const { data } = node
			return (
				<StyledTable>
					{data.map(row => (
						<tr>
							{row.children.map(cell => {
								if (cell.children.length === 1) {
									return (
										<td>
											{cell.children[0].type === "strong" ? (
												<strong>{cell.children[0].children[0].value}</strong>
											) : (
												<span>{cell.children[0].value}</span>
											)}
										</td>
									)
								}
								return <td>{cell.children.map(text => text.value).join("")}</td>
							})}
						</tr>
					))}
				</StyledTable>
			)
		},
	},
})
