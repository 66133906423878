import styled from "@emotion/styled"
import { theme } from "common/colorScheme"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"

interface ITextAreaProps {
	purpleBorder?: boolean;
}

const TextArea = styled.textarea<ITextAreaProps>`
	appearance: none;
	min-height: 80px;
	resize: none;
	border: ${({ purpleBorder }) => `1px solid ${purpleBorder ? "rgba(53, 18, 112, 0.2)" : "#eaebea"}`};
	padding: 25px;
	margin: ${theme.spacing(1, 0)};
	font-size: 1.6rem;
	width: 100%;
	max-width: 100%;
	border-radius: 4px;
	&:invalid,
	&[data-invalid="true"] {
		border-color: var(--error-color);
		outline: none;
		box-shadow: none;
	}
	&:focus {
		border-color: ${({ purpleBorder }) => `${purpleBorder ? "rgba(53, 18, 112, 0.6)" : "#979797"}`};
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		min-height: 137px;
	}
`

export default TextArea
