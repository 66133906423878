import * as React from "react"
import Container from "components/container"
import styled from "@emotion/styled"
import { getColorScheme, getWhiteText, theme as themeCommon } from "common/colorScheme"
import { css } from "@emotion/react"
import { graphql } from "gatsby"
import BrandImagesNew from "components/BrandImagesNew"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import Card from "./Card/Card"
import { H2, P } from "../headings"
import Button from "../button"
import { ISectionProps, SectionLayoutAlign } from "./section.model"
import { elementData, formatMultilineText } from "./utils"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { useSectionTheme } from "./SectionThemeContext"

interface IMediaRightNewProps extends ISectionProps {}

export const hideOnMobile = css`
	display: none;

	${mediaBreakpoint(BreakPoints.LG)} {
		display: block;
	}
`

const containerStyles = css`
	display: block;
	padding-top: ${themeCommon.spacing(6)};
	padding-bottom: ${themeCommon.spacing(6)};

	${mediaBreakpoint(BreakPoints.MD)} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding-top: 0;
		padding-bottom: 0;
	}
`

const SubTitle = styled.h3`
	font-size: 2rem;
	line-height: 2.8rem;
	font-weight: 400;
`

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	flex-direction: column;

	div:not(:nth-of-type(1)) {
		margin-top: ${themeCommon.spacing(2)};
	}

	&.hideOnMobile {
		display: none;

		${mediaBreakpoint(BreakPoints.SM)} {
			display: flex;
		}
	}

	&.hideOnDesktop {
		display: flex;
		margin-top: ${themeCommon.spacing(6)};

		${mediaBreakpoint(BreakPoints.SM)} {
			display: none;
		}
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		width: fit-content;
		flex-direction: row;

		div:not(:nth-of-type(1)) {
			margin-top: 0px;
		}
	}
`

const buttonStyles = css`
	width: 100%;

	&:not(:first-of-type) {
		margin-top: ${themeCommon.spacing(1.5)};

		${mediaBreakpoint(BreakPoints.SM)} {
			margin-left: ${themeCommon.spacing(3)};
			margin-top: 0;
		}
	}
`

const Cover = styled.div`
	display: flex;
	content-visibility: initial;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: ${themeCommon.spacing(7.5, 0)};
		align-items: center;
	}
`

const TextContainer = styled.div`
	width: 100%;

	${mediaBreakpoint(BreakPoints.MD)} {
		width: 42%;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${H2} {
			white-space: pre;
		}
	}
`

const MediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	align-self: stretch;
	align-items: center;

	iframe {
		border-radius: 10px;
		box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
		height: 60vw;
		width: 100%;
		z-index: 5;

		${mediaBreakpoint(BreakPoints.MD)} {
			height: 100%;
			max-height: 384px;
		}
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		align-items: unset;
		width: 68%;
		max-width: 586px;
		margin-left: 60px;
	}
	${mediaBreakpoint(BreakPoints.LG)} {
		margin: 0;
	}
`

const alignContentFirsttStyles = css`
	${MediaContainer} {
		margin-top: ${themeCommon.spacing(6.5)};

		${mediaBreakpoint(BreakPoints.LG)} {
			margin-top: ${themeCommon.spacing(4)};
		}
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${TextContainer} {
			order: 1;
		}
		${MediaContainer} {
			order: 2;
			margin-top: 0;
		}
	}
`

const alignMediaFirstStyles = css`
	${MediaContainer} {
		margin-top: ${themeCommon.spacing(4)};
		margin-bottom: ${themeCommon.spacing(2)};
		order: 1;
	}

	${TextContainer} {
		order: 2;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${MediaContainer} {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
`

const layoutAlignStyles = {
	[SectionLayoutAlign.ContentFirst]: alignContentFirsttStyles,
	[SectionLayoutAlign.MediaFirst]: alignMediaFirstStyles,
}

const MediaRightNew: React.FC<IMediaRightNewProps> = ({
	colorScheme,
	title,
	titleLevel,
	description,
	buttons,
	layoutAlignment,
	customStyle,
	brandImages,
	subTitle,
	embedYoutubeId,
	additionalText,
	cards
}) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()
	const whiteText = theme === "white-text"

	return (
		<section css={[customStyle, getColorScheme(colorScheme)]}>
			<Cover data-element={elementData.cover}>
				<Container css={[containerStyles, layoutAlignStyles[layoutAlignment]]} data-element={elementData.container}>
					<TextContainer data-element={elementData.textContainer} css={getWhiteText(whiteText)}>
						{subTitle && <SubTitle>{subTitle}</SubTitle>}
						<H2 css={getSizeStyles(titleSize)} as={titleLevel || "h2"} data-element="title-text">
							{title}
						</H2>
						{description && <P>{formatMultilineText(description)}</P>}
						<ButtonsContainer className="hideOnMobile">
							{(buttons || []).map(button => {
								const { id } = button

								return (
									<div key={id} css={buttonStyles}>
										<Button {...button} />
									</div>
								)
							})}
						</ButtonsContainer>
						{cards ? (cards || []).map((card, index) => <Card key={`card${index + 1}`} {...card} />) : null}
					</TextContainer>
					<MediaContainer data-element={elementData.bannerMediaContainer}>
						{additionalText && (
							<H2
								as={titleLevel || "h2"} 
								data-element="additional-text"
								css={getSizeStyles(titleSize)}
								sectionTheme={theme}
								dangerouslySetInnerHTML={{ __html: additionalText?.childMarkdownRemark.html }}
							/>
						)}
						{embedYoutubeId ? (
							<iframe
								src={`https://www.youtube.com/embed/${embedYoutubeId}`}
								title="YouTube video player"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							/>
						) : null}
					</MediaContainer>
				</Container>
				{brandImages && <BrandImagesNew brandImages={brandImages} />}
			</Cover>
		</section>
	)
}

export const query = graphql`
	fragment BrandImagesNew on ContentfulBrandImagesNew {
		__typename
		heading {
			heading
		}
		logos {
			...Media160
		}
	}
`

export default React.memo(MediaRightNew)
