import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import HighlightCard from "components/ArticleCard/HighlightCard"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"
import { ISectionProps } from "./section.model"
import Container from "../container"
import { H2 } from "../headings"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { getColorScheme } from "../../common/colorScheme"
import { useSectionTheme } from "./SectionThemeContext"
import Button from "../button"

const PressHighlightStyles = styled.section`
	padding: 10rem 0;
`

const HighlightsContainer = styled(Container)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
`
const headerStyles = css`
	margin-bottom: 6rem;
	text-align: center;
`
const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
`

const PressHighlight = ({ title, titleLevel, buttons, cards, colorScheme }: ISectionProps) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()

	return (
		<PressHighlightStyles css={getColorScheme(colorScheme)}>
			{title ? (
				<H2 css={[getSizeStyles(titleSize), headerStyles]} sectionTheme={theme} as={titleLevel || "h2"}>
					{title}
				</H2>
			) : null}
			<HighlightsContainer>
				{cards &&
					cards.map(({ id, title: cardTitle, image, description, link }) => {
						return (
							<HighlightCard
								key={id}
								title={cardTitle}
								heroImage={image}
								description={description.description}
								link={link}
							/>
						)
					})}
			</HighlightsContainer>
			{buttons && buttons.length ? (
				<ButtonsContainer>
					{(buttons || []).map(button => {
						const { id } = button
						return <Button key={id} {...button} />
					})}
				</ButtonsContainer>
			) : null}
		</PressHighlightStyles>
	)
}

export default PressHighlight
