import { GridProps } from "components/GridItem/GridItem"
import { IJsonContentfulField } from "common/types"
import { SerializedStyles } from "@emotion/react"
import { MediaProps } from "components/Media"
import { PageReference } from "components/ReferencesSection/types"
import { FormProps } from "components/Form/BlogPost"
import { CategoryProps } from "components/Category"
import { IAccordionProps } from "components/Accordion"
import { ButtonProps } from "../button"
import { CardProps } from "./Card/Card"
import { IColorScheme } from "../../common/colorScheme"

export type ISectionVariant =
	| "banner-left"
	| "media-left"
	| "banner-center"
	| "banner-center-new"
	| "image-right"
	| "image-right-new"
	| "cards-right"
	| "single-review"
	| "with-aside"
	| "press-highlight"
	| "article"
	| "media-section"
	| "cards-left"
	| "banner-section"
	| "title-text-horizontal"

export enum MediaSize {
	VerySmall = "verysmall",
	Small = "small",
	Medium = "medium",
	MediumSmall = "medium-small",
	Large = "large",
	Full = "full",
}

export enum SectionLayoutAlign {
	MediaFirst = "media-first",
	ContentFirst = "content-first",
}

export enum SectionMediaAlign {
	Left = "left",
	Right = "right",
	Center = "center",
}

export interface IFileUrl {
	file: {
		url?: string
	}
}

export interface IBrandImages {
	logos?: MediaProps[]
	heading?: {
		heading: string
	}
}

export interface IFile {
	id: string
	url: string
	contentType: string
	details: {
		image: {
			height: number
			width: number
		}
		size: number
	}
}

export interface IMarkdown {
	childMarkdownRemark: {
		html: string
	}
}

export type ITitleLevel = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

export type ITheme = "default" | "white-text"

export interface ISectionProps {
	__typename: string
	id: string
	variant: ISectionVariant
	colorScheme?: IColorScheme
	backgroundGradientsBottomLeft?: IFileUrl
	backgroundGradientsBottomRight?: IFileUrl
	backgroundGradientsTopLeft?: IFileUrl
	backgroundGradientsTopRight?: IFileUrl
	backgroundGradientsWidth?: number
	title: string | null
	titleLevel?: ITitleLevel
	anchor: string | null
	description?: string
	video?: IFileUrl
	image?: any
	buttons?: ButtonProps[]
	cardsTitle?: string
	cards?: CardProps[]
	titleSize: ITitleSizeValue | null
	layoutAlignment: SectionLayoutAlign
	mediaAlign: SectionMediaAlign
	mediaSize: MediaSize | null
	media?: any
	mobileMedia?:any
	embedYoutubeId?: string
	customStyle?: SerializedStyles
	additionalText?: IMarkdown
	files?: any[]
	videoAutoplay?: boolean
	playVideoWhenInView?: boolean
	showVideoControls?: boolean
	brandImages?: IBrandImages
	brandImagesScrolling?: boolean
	scrollCardsOnMobile?: boolean
	videoShareUrl?: string
	muted?: boolean
	videoPosterImage?: any
	loop?: boolean
	innerHTML?: string
	secondaryImage?: MediaProps
	subTitle?: string
	theme?: ITheme
	pageReferences?: PageReference[]
	bulletpoints?: CardProps[]
	newsletterFormPurple?: FormProps
	cardsHorizontal?: boolean
	titleCardsRight?: string
	mediaCardsRight?: any
	mediaToFitSection?: any
	category: CategoryProps | null
	accordionComponent: unknown
	cardsDescription?: string
	typerwriterContent?: string
}

export interface ISectionQuery {
	__typename: string
	id: string
	variant: any
	colorScheme?: IColorScheme
	backgroundGradientsBottomLeft?: IFileUrl
	backgroundGradientsBottomRight?: IFileUrl
	backgroundGradientsTopLeft?: IFileUrl
	backgroundGradientsTopRight?: IFileUrl
	backgroundGradientsWidth?: number
	layoutAlignment: SectionLayoutAlign | null
	mediaAlign: SectionMediaAlign | null
	anchor: string | null
	title: {
		title: string
	} | null
	description: {
		description: string
	} | null
	video?: IFileUrl
	image?: any
	buttons?: ButtonProps[]
	cardsTitle?: string
	cards?: CardProps[]
	titleSize: ITitleSizeValue | null
	titleLevel?: ITitleLevel
	grid: GridProps
	mediaSize: MediaSize | null
	media?: any
	mobileMedia?:any
	embedYoutubeId?: string
	customSpacing?: IJsonContentfulField
	additionalText?: IMarkdown
	videoAutoplay?: boolean
	playVideoWhenInView?: boolean
	showVideoControls?: boolean
	brandImages?: IBrandImages
	brandImagesScrolling?: boolean
	scrollCardsOnMobile?: boolean
	videoShareUrl?: string
	videoPosterImage?: any
	secondaryImage?: MediaProps
	subTitle?: string
	files?: any[]
	theme?: ITheme
	pageReferences?: PageReference[]
	bulletpoints?: CardProps[]
	newsletterFormPurple?: FormProps
	cardsHorizontal?: boolean
	titleCardsRight?: string
	mediaCardsRight?: any
	mediaToFitSection?: any
	category: CategoryProps | null
	accordionComponent?: IAccordionProps []
	cardsDescription?: string
	typerwriterContent?: string
}

export type ITitleSizeValue = "60/34" | "48/30" | "42/32" | "40/30" | "36/24" | "32/28" | "28/24" | "20/16"
export type IThemeValue = "default" | "white-text"
