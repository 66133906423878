/* eslint-disable react/no-unknown-property */
import React from "react"
import { css } from "@emotion/react"
import { graphql } from "gatsby"
import { Grid } from "components/GridItem/GridItem"
import Accordion from "components/Accordion"
import BannerLeft from "./BannerLeft"
import BannerCenter from "./BannerCenter"
import BannerCenterNew from "./BannerCenterNew"
import ImageRight from "./ImageRight"
import ImageRightNew from "./ImageRightNew"
import CardsRight from "./CardsRight"
import SingleReview from "./SingleReview"
import WithAside from "./WithAside"
import PressHighlight from "./PressHighlight"
import Article from "./Article"
import MediaSection from "./MediaSection"
import MediaRight from "./MediaRight"
import BannerSection from "./BannerSection"
import { ISectionQuery, ISectionVariant, ISectionProps, SectionMediaAlign, SectionLayoutAlign } from "./section.model"
import { TitleSizeContext } from "./TitleSizeContext"
import { SectionThemeContext } from "./SectionThemeContext"
import { getCustomStyle } from "./utils"
import AlignLeft from "./AlignLeft"
import CardsLeft from "./CardsLeft"
import TitleTextHorizontal from "./TitleTextHorizontal"

type ISectionMap = {
	[variant in ISectionVariant]: React.NamedExoticComponent<ISectionProps>
}

const SectionMap: ISectionMap = {
	"banner-left": BannerLeft,
	"banner-center": BannerCenter,
	"banner-center-new": BannerCenterNew,
	"image-right": ImageRight,
	"image-right-new": ImageRightNew,
	"cards-right": CardsRight,
	"single-review": SingleReview,
	"with-aside": WithAside,
	"media-section": MediaSection,
	"media-right": MediaRight,
	"press-highlight": PressHighlight,
	article: Article,
	"align-left": AlignLeft,
	"cards-left": CardsLeft,
	"banner-section": BannerSection,
	"title-text-horizontal" : TitleTextHorizontal
}

const NoSection = ({ variant }) => {
	return (
		<h1
			css={css`
				display: flex;
				justify-content: center;
				align-items: center;
				min-height: 100vh;
				background: #f1c40f;
				margin: 0;
				font-size: 3rem;
				color: #fff;
			`}
		>
			Variant under construction: {variant}
		</h1>
	)
}

const Section: React.FC<ISectionQuery> = props => {
	const { variant, secondaryImage } = props
	const Component = SectionMap[variant]

	if (Component) {
		const {
			children,
			title,
			subTitle,
			description,
			anchor,
			cardsTitle,
			cards,
			scrollCardsOnMobile,
			buttons,
			colorScheme,
			backgroundGradientsTopLeft,
			backgroundGradientsTopRight,
			backgroundGradientsBottomLeft,
			backgroundGradientsBottomRight,
			backgroundGradientsWidth,
			files,
			layoutAlignment,
			media,
			embedYoutubeId,
			mediaAlign,
			mediaSize,
			videoPosterImage,
			videoAutoplay,
			videoShareUrl,
			playVideoWhenInView,
			showVideoControls,
			titleSize,
			titleLevel,
			theme,
			grid,
			customSpacing,
			additionalText,
			brandImages,
			brandImagesScrolling,
			pageReferences,
			mobileMedia,
			bulletpoints,
			newsletterFormPurple,
			cardsHorizontal,
			titleCardsRight,
			mediaCardsRight,
			mediaToFitSection,
			category,
			accordionComponent,
			cardsDescription,
			typerwriterContent
		} = props

		if (media) {
			media.alt = (title && title.title) || (description && description.description)
		}

		return (
			<TitleSizeContext.Provider value={titleSize}>
				<SectionThemeContext.Provider value={theme}>
					<Component
						anchor={anchor}
						backgroundGradientsBottomLeft={backgroundGradientsBottomLeft}
						backgroundGradientsBottomRight={backgroundGradientsBottomRight}
						backgroundGradientsTopLeft={backgroundGradientsTopLeft}
						backgroundGradientsTopRight={backgroundGradientsTopRight}
						backgroundGradientsWidth={backgroundGradientsWidth}
						buttons={buttons || []}
						media={media}
						embedYoutubeId={embedYoutubeId}
						title={title && title.title}
						description={description && description.description}
						cardsTitle={cardsTitle}
						cards={cards}
						colorScheme={colorScheme}
						layoutAlignment={layoutAlignment || SectionLayoutAlign.ContentFirst}
						mediaAlign={mediaAlign || SectionMediaAlign.Right}
						mediaSize={mediaSize}
						customStyle={getCustomStyle(customSpacing)}
						additionalText={additionalText}
						files={files}
						titleLevel={titleLevel}
						theme={theme}
						videoAutoplay={videoAutoplay}
						playVideoWhenInView={playVideoWhenInView}
						showVideoControls={showVideoControls}
						brandImages={brandImages}
						brandImagesScrolling={brandImagesScrolling}
						scrollCardsOnMobile={scrollCardsOnMobile}
						videoShareUrl={videoShareUrl}
						videoPosterImage={videoPosterImage}
						secondaryImage={secondaryImage}
						subTitle={subTitle}
						pageReferences={pageReferences}
						mobileMedia={mobileMedia}
						bulletpoints={bulletpoints}
						newsletterFormPurple={newsletterFormPurple}
						cardsHorizontal={cardsHorizontal}
						titleCardsRight={titleCardsRight}
						mediaCardsRight={mediaCardsRight}
						mediaToFitSection={mediaToFitSection}
						category={category}
						cardsDescription={cardsDescription}
						typerwriterContent={typerwriterContent}
					>
						{children}
						<Grid {...grid} />
						<Accordion  {...accordionComponent} /> 
					</Component>
				</SectionThemeContext.Provider>
			</TitleSizeContext.Provider>
		)
	}
	return <NoSection variant={variant} />
}

export const query = graphql`
	fragment Section on ContentfulLandingSections {
		__typename
		id
		variant
		backgroundGradientsBottomLeft {
			file {
				url
			}
		}
		backgroundGradientsBottomRight {
			file {
				url
			}
		}
		backgroundGradientsTopLeft {
			file {
				url
			}
		}
		backgroundGradientsTopRight {
			file {
				url
			}
		}
		backgroundGradientsWidth
		brandImages {
			...BrandImagesNew
		}
		brandImagesScrolling
		bulletpoints {
			...Card
		}
		colorScheme {
			...ColorScheme
		}
		title {
			title
		}
		subTitle
		description {
			description
		}
		mobileMedia {
			...Media800
		}
		media {
			...Media800
		}
		mediaToFitSection {
			...MediaOriginal
		}
		embedYoutubeId
		buttons {
			...Button
		}
		cardsTitle
		cardsDescription
		cards {
			...Card
		}
		category {
			...Category
		  }
		scrollCardsOnMobile
		files {
			title
			author
			releaseDate
			file {
				file {
					url
				}
			}
		}
		layoutAlignment
		mediaAlign
		mediaSize
		newsletterFormPurple {
			...Form
		}
		titleSize
		titleLevel
		theme
		anchor
		typerwriterContent
		grid {
			...Grid
		}
		accordionComponent {
			...Accordion
		}
		customSpacing {
			internal {
				content
			}
		}
		additionalText {
			additionalText
			childMarkdownRemark {
				html
			}
		}
		cardsHorizontal
        titleCardsRight
        mediaCardsRight {
			...Media400
		}
		videoAutoplay
		playVideoWhenInView
		showVideoControls
		videoShareUrl
		videoPosterImage {
			...MediaOriginal
		}
		pageReferences {
			id
			categoryImage {
				...MediaReviewLogo
			}
			longTitle: title {
				title
			}
			description {
				description
			}
			category
			heroImage {
				...MediaOriginal
			}
			slug
		}
	}

	fragment ColorScheme on ContentfulColorScheme {
		__typename
		id
		gradientType
		backgroundImage {
			...MediaOriginal
		}
		backgroundImagePosition
		backgroundImageSize
		colors
	}
`

export default React.memo(Section)
