import styled from "@emotion/styled"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"

interface ITextFieldProps {
	purpleBorder?: boolean;
}

const TextField = styled.input<ITextFieldProps>`
	appearance: none;
	line-height: 48px;
	height: 48px;
	font-size: 1.6rem;
	border: ${({ purpleBorder }) => `1px solid ${purpleBorder ? "rgba(53, 18, 112, 0.2)" : "#eaebea"}`};
	background-color: #fff;
	padding: 0 12px;
	max-width: 100%;
	width: 100%;
	border-radius: 4px;

	&:invalid,
	&[data-invalid="true"] {
		border-color: var(--error-color);
		outline: none;
		box-shadow: none;
	}

	&:focus {
		border-color: ${({ purpleBorder }) => `${purpleBorder ? "rgba(53, 18, 112, 0.6)" : "#979797"}`};
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		padding: 0 25px;
	}
`

export default TextField
