/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Media, { MediaProps } from "components/Media"

export interface IListItemProps {
  listItemTitle: string
  listMedia: MediaProps[]
  id: number
}

const Item = styled.li `
  padding: 1.6rem 0 0;
  margin-bottom: 0;
  
`
const ListItemTitle = styled.p`
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2.6rem;
  align-self: center;
  overflow-wrap: anywhere;

`
const ItemContainer = styled.div `
  display: flex;
`
const ListItemImage = styled.div `
  padding: 0 2rem;
`
const ListItem :  React.FC<IListItemProps> = ({listItemTitle, listMedia, id})  => {
	return (
		<Item key={id}>
			<ItemContainer>
				<ListItemImage><Media {...listMedia}/></ListItemImage>
				<ListItemTitle>{listItemTitle}</ListItemTitle>         
			</ItemContainer>
		</Item>
	)
}


	
	
export const query = graphql`

	fragment ListItem on ContentfulListItem {
    __typename
    id
    listItemTitle
    listMedia {
      ...MediaIcon
    }
	}
`
export default ListItem