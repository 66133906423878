import { IOption } from "components/Header/types"
import React, { useState } from "react"
import styled from "@emotion/styled"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"

interface DropDownProps {
	data?: IOption[],
    updateDropdown: (arg: string) => void
}
const DropdownContainer = styled.div`
    background-color: white;
    text-align: center;
    margin: 5% 0px;
  
`
const DropdownHeader = styled.div`
    padding: 40px 15px 15px 15px;
    cursor: pointer;
    text-align: center;
    display: flex;
    font-size: 12px;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #31135E;
    justify-content: center;
    align-items: center;
    span {
        padding-right: 4%;
    }
`
const DropdownItem = styled.div`
    padding: 10px;
    cursor: pointer;
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    &:hover {
        color: #31135E;
    }
`
const DropdownItemDot = styled.span<any>`
    opacity: ${({selected}) => (selected ? '1': '0')};
    color: #91A5BE;
    transition: all .2s ease-in-out;
`
const ArrowIcon = styled.div<any>`
    height: 18px;
    width: 18px;
    min-height: 18px;
    min-width: 18px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    border-radius: 8px;
    border-radius: 50%;
    z-index: 1;
  
`
const DropdownBody = styled.div<any>`
    padding: 5px 40px;
    border-top: 1px solid #E5E8EC;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0,0,0,.1);
    display: ${({ open }) => (open  ? 'block' : 'none')};
    background-color: white;
    z-index:1;

    ${mediaBreakpoint(BreakPoints.MD)} {
		position:absolute;
        background-color: white;
        z-index:1000;
	}
`
const Dropdown: React.FC<DropDownProps> = ({
	data,
	updateDropdown
}) => {
	const [isOpen, setOpen] = useState(false)
	const [items] = useState(data)
	const [selectedItem, setSelectedItem] = useState(null)

	const toggleDropdown = () => setOpen(!isOpen)
    
	const handleItemClick = (id: string) => {
		// eslint-disable-next-line no-unused-expressions
		selectedItem === id ? setSelectedItem(null) : setSelectedItem(id)
	    updateDropdown(id)
	}
    
	return (
		<DropdownContainer>
			<DropdownHeader onClick={toggleDropdown}>
				<span> {selectedItem ? items.find(item => item.id === selectedItem).label : `SORT BY`} </span>
				<ArrowIcon open={isOpen}/>
			</DropdownHeader>
			<DropdownBody open={isOpen}>
				{items.map(item => (
					<DropdownItem key={item.id} onClick={() => handleItemClick(item.id)} id={item.id}>
						<DropdownItemDot selected={item.id === selectedItem}>• </DropdownItemDot>
						{item.label}
					</DropdownItem>
				))}
			</DropdownBody>
		</DropdownContainer>
	)
}


export default Dropdown
