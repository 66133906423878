import styled from "@emotion/styled"
import { theme } from "common/colorScheme"
import * as React from "react"

interface IBackgroundAnimationProps {
	backgroundGradientsTopLeft?: any
	backgroundGradientsTopRight?: any
	backgroundGradientsBottomLeft?: any
	backgroundGradientsBottomRight?: any
	width?: number
}

interface IBackgroundAnimationContainerProps {
	width?: number
}

const BackgroundAnimationContainer = styled.div<IBackgroundAnimationContainerProps>`
	position: absolute;
	height: 100%;
	width: ${({ width }) => (width ? `${width}%` : "100%")};
	top: 0;
	left: 0;
	pointer-events: none;
`

const TopLeft = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(-40%) translateY(-40%);
	img {
		animation: ${theme.animation.breathing} 4s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
	}
`

const TopRight = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	transform: translateX(40%) translateY(-40%);
	img {
		animation: ${theme.animation.breathing} 4s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
		animation-delay: 2s;
	}
`

const BottomLeft = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translateX(-40%) translateY(40%);
	img {
		animation: ${theme.animation.breathing} 4s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
		animation-delay: 4s;
	}
`

const BottomRight = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translateX(40%) translateY(40%);
	img {
		animation: ${theme.animation.breathing} 4s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
		animation-delay: 3s;
	}
`

const BackgroundAnimation: React.FC<IBackgroundAnimationProps> = ({
	backgroundGradientsTopLeft,
	backgroundGradientsTopRight,
	backgroundGradientsBottomLeft,
	backgroundGradientsBottomRight,
	width,
}) => {
	return (
		<BackgroundAnimationContainer width={width}>
			{backgroundGradientsTopLeft && (
				<TopLeft data-element="top-left">
					<img src={backgroundGradientsTopLeft.file?.url} alt="Background Gradient" />
				</TopLeft>
			)}
			{backgroundGradientsTopRight && (
				<TopRight data-element="top-right">
					<img src={backgroundGradientsTopRight.file?.url} alt="Background Gradient" />
				</TopRight>
			)}
			{backgroundGradientsBottomLeft && (
				<BottomLeft data-element="bottom-left">
					<img src={backgroundGradientsBottomLeft.file?.url} alt="Background Gradient" />
				</BottomLeft>
			)}
			{backgroundGradientsBottomRight && (
				<BottomRight data-element="bottom-right">
					<img src={backgroundGradientsBottomRight.file?.url} alt="Background Gradient" />
				</BottomRight>
			)}
		</BackgroundAnimationContainer>
	)
}
export default React.memo(BackgroundAnimation)
