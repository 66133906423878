import * as React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import { IColorScheme, getColorScheme } from "common/colorScheme"
import { SectionThemeContext } from "components/sections/SectionThemeContext"
import { IThemeValue } from "components/sections/section.model"
import styled from "@emotion/styled"
import Button, { ButtonProps, ButtonBase } from "../button"
import Container from "../container"
import { H3 } from "../headings"
import Media, { MediaProps } from "../Media"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"

export enum IBrandImagesMask {
	Original = "original",
	Black = "black",
	White = "white",
}

const isCompact = (variant?: IVariantValue) => variant && variant === "compact"
const isLarge = (variant?: IVariantValue) => variant && variant === "large"

const getCoverStyles = (variant?: IVariantValue) => css`
	padding: ${isCompact(variant) ? "4rem" : "114px"} 0;
`

const getHeadingStyles = (variant?: IVariantValue) => css`
	margin: 0 0 52px;
	${isCompact(variant)
		? `
        font-size: 2rem;
        text-align: left;
        width: 100%;
        text-align: center;
    `
		: `
        text-align: center;
    `}
	${mediaBreakpoint(BreakPoints.SM)} {
		${isCompact(variant)
		? `
            font-size: 2rem;
            width: 65%;
            text-align: left;
        `
		: `
        `}
	}
`

const getContentContainerStyles = (variant?: IVariantValue) => {
	if (isCompact(variant)) {
		return css`
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			${mediaBreakpoint(BreakPoints.SM)} {
				width: 50%;
			}
		`
	}

	return css``
}

const getLogosContainer = (variant?: IVariantValue) => css`
	width: 100%;
	display: flex;
	justify-content: ${isLarge(variant) ? "center" : "space-between"};
	margin: 0 0 42px;
	flex-direction: column;
	align-items: center;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
		align-items: ${isCompact(variant) ? "flex-start" : "center"};
		flex-wrap: ${isCompact(variant) ? "wrap" : "nowrap"};
	}
	${isCompact(variant) &&
	`
            max-width: 400px;
            img {
                height: 42px;
            }
        `}
`

const getImagesMask = (mask: IBrandImagesMask) => {
	switch (mask) {
	case IBrandImagesMask.Black: {
		return css`
				img {
					filter: brightness(0);
				}
			`
	}
	case IBrandImagesMask.White: {
		return css`
				img {
					filter: brightness(0) invert(1);
				}
			`
	}
	case IBrandImagesMask.Original:
	default:
		return css``
	}
}

interface IBrandImagesComponentProps {
	variant?: IVariantValue
}

const LogoWrapper = styled.div`
	justify-content: space-between;
	width: 160px;
	height: 42px;
	margin: 6px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: ${(props: IBrandImagesComponentProps) => (isCompact(props.variant) ? "50%" : "initial")};
		max-height: ${(props: IBrandImagesComponentProps) =>
	// eslint-disable-next-line no-nested-ternary
		isCompact(props.variant) ? "42px" : isLarge(props.variant) ? "80px" : "46px"};
		height: ${(props: IBrandImagesComponentProps) =>
	// eslint-disable-next-line no-nested-ternary
		isCompact(props.variant) ? "42px" : isLarge(props.variant) ? "80px" : "46px"};
		width: initial;
		max-width: ${(props: IBrandImagesComponentProps) => (isCompact(props.variant) ? "calc(50% - 12px)" : "initial")};
		${(props: IBrandImagesComponentProps) => (isLarge(props.variant) ? "margin: 0 30px 12px;" : "margin: 0 0 12px;")};
		align-items: ${(props: IBrandImagesComponentProps) => (isCompact(props.variant) ? "flex-start" : "initial")};
	}
`

const buttonsContainerStyles = css`
	display: flex;
	justify-content: center;
`

const buttonStyles = css`
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	${ButtonBase}[data-variant="hollow"] {
		font-size: 2rem;
	}
`

export type IVariantValue = "full-width" | "compact" | "large"

export interface BrandImagesProps {
	heading: string
	images: MediaProps[]
	buttons: ButtonProps[] | null
	colorScheme?: IColorScheme
	theme?: IThemeValue
	variant?: IVariantValue
	imagesMask: IBrandImagesMask | null
}

const BrandImages: React.FC<BrandImagesProps> = ({
	heading,
	images,
	buttons,
	colorScheme,
	theme,
	variant,
	imagesMask,
}) => {
	const imgStyles = React.useMemo(() => {
		return variant === "compact"
			? {
				objectFit: "contain",
				objectPosition: "left center",
			  }
			: {
				objectFit: "contain",
			  }
	}, [variant])

	return (
		<SectionThemeContext.Provider value={theme}>
			<div
				css={[
					getCoverStyles(variant),
					getColorScheme(colorScheme),
					getImagesMask(imagesMask || IBrandImagesMask.Original),
				]}
			>
				<Container>
					<div css={getContentContainerStyles(variant)}>
						<H3 css={getHeadingStyles(variant)} sectionTheme={theme}>
							{heading}
						</H3>
						<div css={getLogosContainer(variant)}>
							{images.map(image => {
								return (
									<LogoWrapper key={image.id} variant={variant}>
										<Media imgStyle={imgStyles} {...image} />
									</LogoWrapper>
								)
							})}
						</div>
						<div css={buttonsContainerStyles}>
							{(buttons || []).map(button => {
								const { id } = button

								return (
									<div css={buttonStyles} key={id}>
										<Button {...button} />
									</div>
								)
							})}
						</div>
					</div>
				</Container>
			</div>
		</SectionThemeContext.Provider>
	)
}

export const query = graphql`
	fragment BrandImages on ContentfulBrandImagesSection {
		__typename
		id
		heading
		images {
			...Media160
		}
		buttons {
			...Button
		}
		colorScheme {
			...ColorScheme
		}
		theme
		variant
		imagesMask
	}
`

export default React.memo(BrandImages)
