import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { richTextFromMarkdown } from "@contentful/rich-text-from-markdown"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { getColorScheme } from "common/colorScheme"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { RichTextOptions, ImageToRichText } from "../../utils/richText"
import { ISectionProps } from "./section.model"
import Container from "../container"
import { H2 } from "../headings"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"

const ArticleStles = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8rem 0;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
	}
`
const AsideStyles = styled.aside`
	flex: 1;
	${mediaBreakpoint(BreakPoints.SM)} {
		margin-right: 6rem;
	}
`
const ContentStyles = styled.div`
	flex: 2;
	p {
		font-size: 2rem;
		line-height: 2.8rem;
	}
`

const Article = ({ title, titleLevel, additionalText, colorScheme }: ISectionProps) => {
	const [contentFromMarkdown, setContentFromMarkdown] = useState(null)
	const titleSize = useTitleSize()

	useEffect(() => {
		if (additionalText) {
			richTextFromMarkdown(additionalText.additionalText, ImageToRichText).then(richText =>
				setContentFromMarkdown(documentToReactComponents(richText, RichTextOptions))
			)
		}
	}, [additionalText])

	return (
		<section css={getColorScheme(colorScheme)}>
			<Container>
				<ArticleStles>
					<AsideStyles>
						<H2 css={getSizeStyles(titleSize)} as={titleLevel || "h2"}>
							{title}
						</H2>
					</AsideStyles>
					<ContentStyles>{contentFromMarkdown}</ContentStyles>
				</ArticleStles>
			</Container>
		</section>
	)
}

export default Article
