import React, { useState, useEffect, memo } from "react"
import { css } from "@emotion/react"
import { richTextFromMarkdown } from "@contentful/rich-text-from-markdown"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { getColorScheme } from "common/colorScheme"
import styled from "@emotion/styled"
import { reviewQuote } from "components/BrandImagesWithReviews/Reviews"
import { RichTextOptions, ImageToRichText } from "../../utils/richText"
import Container from "../container"
import { H2, P } from "../headings"
import Media from "../Media"
import { useSectionTheme } from "./SectionThemeContext"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { ISectionProps } from "./section.model"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"

const containerStyles = css`
	display: flex;
	align-items: center;
	flex-direction: column;

	${mediaBreakpoint(BreakPoints.SM)} {
		flex-direction: row;
	}
`
const descriptionStyles = css`
	margin-top: 7rem;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex: 2;
		margin: 7rem 4rem 7rem 0;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		flex: 1;
		margin: 7rem 9rem 7rem 0;
	}
`
const reviewStyles = css`
	margin-bottom: 7rem;
	margin-top: 3rem;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex: 3;
		margin: 7rem 0;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		flex: 2;
	}
`

const BrandLogo = styled(Media)`
	margin: 0 0 2rem;
`

const SingleReview = ({ title, titleLevel, description, media, colorScheme, additionalText }: ISectionProps) => {
	const [contentFromMarkdown, setContentFromMarkdown] = useState(null)
	const titleSize = useTitleSize()
	const theme = useSectionTheme()

	useEffect(() => {
		if (additionalText) {
			richTextFromMarkdown(additionalText.additionalText, ImageToRichText).then(richText =>
				setContentFromMarkdown(documentToReactComponents(richText, RichTextOptions))
			)
		}
	}, [additionalText])

	return (
		<section css={getColorScheme(colorScheme)}>
			<Container css={containerStyles}>
				{ media || description ?
					<div css={descriptionStyles}>
						{media && <BrandLogo {...media} />}
						<P sectionTheme={theme}>{description}</P>
					</div> : null }
				<div css={reviewStyles}>
					<div css={reviewQuote}>“</div>
					<H2 css={getSizeStyles(titleSize)} sectionTheme={theme} as={titleLevel || "h2"}>
						{title}
					</H2>
					{contentFromMarkdown && contentFromMarkdown}
				</div>
			</Container>
		</section>
	)
}

export default memo(SingleReview)
