/* eslint-disable react/self-closing-comp */
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { getWhiteText, theme } from "common/colorScheme"
import Container from "components/container"
import { H6 } from "components/headings"
import Media from "components/Media"
import { IBrandImages } from "components/sections/section.model"
import React from "react"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"

interface IBrandImagesNewProps {
	brandImages: IBrandImages
	blackText?: boolean
}

const Wrapper = styled.div`
	width: 100%;
	margin-top: 0px;
	padding: 0px 0 60px;

	${mediaBreakpoint(BreakPoints.SM)} {
		margin-top: 30px;
		padding: 46px 0 36px;
	}
`

const heading = css`
	font-weight: 600;
	color: ${theme.color.black};
`

const LogosContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: space-evenly;

	${mediaBreakpoint(BreakPoints.LG)} {
		flex-wrap: nowrap;
		gap: 0px;
	}
`

const Logo = css`
	flex-grow: 1;
	max-width: 120px !important;
	max-height: 35px;
	margin-bottom: 10px;

	${mediaBreakpoint(BreakPoints.XL)} {
		max-width: initial !important;
		max-height: initial;
		padding: 0px 20px;
		margin-bottom: 0;

		&:first-of-type {
			padding: 0 20px 0 0;
		}

		&:last-of-type {
			padding: 0 0 0 20px;
		}
	}
`

const BrandImagesNew: React.FC<IBrandImagesNewProps> = ({ brandImages, blackText }) => {
	return (
		<Container  data-element="brand-images-container">
			<Wrapper css={getWhiteText(!blackText)} data-element = "wrapper">
				<H6 css={heading}>{brandImages.heading?.heading}</H6>
				<LogosContainer data-element = "logos-container">
					{brandImages.logos && brandImages.logos.map(logo => <Media css={Logo} key={logo.id} {...logo} />)}
				</LogosContainer>
			</Wrapper>
		</Container>
	)
}

export default React.memo(BrandImagesNew)
