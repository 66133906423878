/* eslint-disable react/no-unknown-property */
import React from "react"
import { ContentfulTypes, IJsonContentfulField } from "common/types"
import Card from "components/sections/Card/Card"
import { graphql } from "gatsby"
import Form from "components/Form"
import { css } from "@emotion/react"
import { mediaBreakpoint, BreakPoints } from "settings/breakpoints"

import styled from "@emotion/styled"
import { getCustomStyle } from "components/sections/utils"

export interface GridItemProps {
	__typename: string
	[key: string]: any
}

export type ILayoutOption =
	| "free"
	| "1"
	| "1/2 + 1/2"
	| "1/3 + 1/3 + 1/3"
	| "1/4 + 1/4 + 1/4 + 1/4"
	| "1/3 + 2/3"
	| "2/3 + 1/3"
	| "40 + 60"

export type IGridAlignmentOption = "left" | "right" | "center" | "space-around"

export interface GridProps {
	id: string
	items: GridItemProps[]
	layout: ILayoutOption
	spacing: IJsonContentfulField
	alignment: IGridAlignmentOption
	title: string
}

const defaultFormStyles = css`
	margin: 32px -36px 0 -36px;
	box-shadow: 0 7px 18px 0 rgba(52, 14, 112, 0.15);
	${mediaBreakpoint(BreakPoints.SM)} {
		margin: 32px;
	}
`

const GridItem: React.FC<GridItemProps> = ({ __typename, ...rest }) => {
	switch (__typename) {
	case ContentfulTypes.Card:
		return <Card {...(rest as any)} />
	case ContentfulTypes.Form:
		return <Form css={defaultFormStyles} {...(rest as any)} />
	case ContentfulTypes.Grid:
		return (
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
			<Grid {...(rest as any)} />
		)
	default: {
		return null
	}
	}
}

const getAlignment = (alignment: IGridAlignmentOption) => {
	switch (alignment) {
	case "center":
		return `justify-content: center;`
	case "right":
		return `justify-content: flex-end;`
	case "left":
		return `justify-content: flex-start;`
	case "space-around":
		return `justify-content: space-around;`
	default:
		return ""
	}
}

const getGridStyles = (alignment: IGridAlignmentOption) => css`
	flex-wrap: wrap;
	display: flex;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.MD)} {
		margin: 0 -32px;
		${getAlignment(alignment)}
		flex-direction: row;
	}
`

export const GridItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100%;
`

const getItemStyles = (layout: ILayoutOption) => {
	switch (layout) {
	case "free":
		return css``
	case "1":
		return css`
				${mediaBreakpoint(BreakPoints.MD)} {
					flex-grow: 1;
					flex-basis: 100%;
					max-width: 100%;
				}
			`
	case "1/2 + 1/2":
		return css`
				${mediaBreakpoint(BreakPoints.MD)} {
					flex-grow: 1;
					flex-basis: 50%;
					max-width: 50%;
				}
			`
	case "1/3 + 2/3":
		return css`
				${mediaBreakpoint(BreakPoints.MD)} {
					flex-basis: 0;
					:nth-of-type(1) {
						flex-grow: 1;
					}
					:nth-of-type(2) {
						flex-grow: 2;
					}
				}
			`
	case "40 + 60":
		return css`
				${mediaBreakpoint(BreakPoints.MD)} {
					flex-basis: 0;
					:nth-of-type(1) {
						flex-grow: 1;
						flex-basis: 40%;
						max-width: 40%;
					}
					:nth-of-type(2) {
						flex-grow: 1;
						flex-basis: 60%;
						max-width: 60%;
					}
				}
			`
	case "2/3 + 1/3":
		return css`
				${mediaBreakpoint(BreakPoints.MD)} {
					flex-basis: 0;
					:nth-of-type(1) {
						flex-grow: 2;
					}
					:nth-of-type(2) {
						flex-grow: 1;
					}
				}
			`
	case "1/3 + 1/3 + 1/3":
		return css`
				${mediaBreakpoint(BreakPoints.MD)} {
					flex-basis: calc(100% / 3);
					max-width: 384px;
					flex-grow: 1;
				}
			`
	default:
		return css``
	}
}

const Title = styled.h2`
	font-size: 3rem;
	padding-left: 0rem;
	${mediaBreakpoint(BreakPoints.MD)}{
		font-size: 4.8rem;
		padding-left: 3rem;
		line-height: 5.2rem
	}
`

// inspired by https://stackoverflow.com/questions/21164024/finding-css-margins-and-paddings-only-with-4-parameters-by-regex#comment31859666_21164300
// const marginValueRegexp = new RegExp(/((-*(\d*\.?\d*)(px|em|rem|%|cm|in|pc|pt|mm|ex)|auto|inherit)[ ]*)/)

// const schema = yup.object().shape({
// 	desktop: yup
// 		.object()
// 		.optional()
// 		.shape({
// 			"margin-bottom": yup.string().optional().matches(marginValueRegexp),
// 			"margin-top": yup.string().optional().matches(marginValueRegexp),
// 		}),
// 	mobile: yup
// 		.object()
// 		.optional()
// 		.shape({
// 			"margin-bottom": yup.string().optional().matches(marginValueRegexp),
// 			"margin-top": yup.string().optional().matches(marginValueRegexp),
// 		}),
// })

export const Grid: React.FC<GridProps> = ({ items, layout, spacing, alignment, title}) => {
	if (!items || items.length === 0) {
		return null
	}

	return (
		<div css={[getGridStyles(alignment), getCustomStyle(spacing)]} data-element="grid-container">
			{ title ? <Title data-element="nested-grid-title">{title}</Title> : null }
			{items.map(item => (
				<GridItemContainer css={getItemStyles(layout)} key={item.id} data-element="item-container">
					<GridItem {...item} />
				</GridItemContainer>
			))}
		</div>
	)
}

export default GridItem

export const query = graphql`
	fragment Grid on ContentfulGrid {
		...GridFields
		name
		items {
			...Card
			...Form
		}
	}

	fragment GridFields on ContentfulGrid {
		__typename
		id
		layout
		alignment
		spacing {
			internal {
				content
			}
		}
	}
`
