/* eslint-disable react/no-danger */
import * as React from "react"
import styled from "@emotion/styled"
import { css, SerializedStyles } from "@emotion/react"
import { CheckMarkIcon } from "../icons"

const FakeCheckbox = styled.span`
	display: inline-block;
	width: 16px;
	height: 16px;
	border: 1px solid #ebeaeb;
	border-radius: 50%;
	margin: 0 10px 2px 0;
	background: #fff;
	position: relative;
`

const Input = styled.input`
	display: none;
	&:checked + ${FakeCheckbox} {
		border-color: var(--main-color);
		background: var(--main-color);
		&:before {
			content: "";
			width: 12px;
			height: 12px;
			display: block;
			mask-image: url(${CheckMarkIcon});
			mask-size: cover;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&[data-invalid="true"] + ${FakeCheckbox} {
		border-color: var(--error-color);
	}
`

const labelStyles = css`
	font-size: 1.2rem;
	line-height: 2.4rem;
	display: flex;
	align-items: center;
	margin: 0 0 3px;
	a {
		margin: 0 0 0 4px;
		color: inherit;
	}
`

interface Props {
	label?: string | React.ReactNode
	invalid?: boolean
	name: string
	ref: any
	customStyles?: SerializedStyles
	labelInnerHTML?: any
}

const Checkbox: React.FC<Props> = React.forwardRef(
	({ label, labelInnerHTML, invalid = false, customStyles, ...other }, ref) => {
		return (
			// eslint-disable-next-line jsx-a11y/label-has-associated-control
			<label css={[labelStyles, customStyles]}>
				<Input
					type="checkbox"
					data-invalid={invalid}
					ref={ref as React.MutableRefObject<HTMLInputElement>}
					{...other}
				/>
				<FakeCheckbox />

				{labelInnerHTML && !label && <span dangerouslySetInnerHTML={labelInnerHTML} />}
				{!labelInnerHTML && label && <span>{label}</span>}
			</label>
		)
	}
)

export default React.memo(Checkbox)
