import * as yup from "yup"
import { FormFieldBase, FieldTypes } from "./FormField"

export const generateFieldValidation = ({ type, required, message }: FormFieldBase) => {
	let yupField = null

	// eslint-disable-next-line default-case
	switch (type) {
	case FieldTypes.TextField:
	case FieldTypes.TextAreaField: {
		yupField = yup.string()
		break
	}
	case FieldTypes.EmailField: {
		yupField = yup.string().email("Please enter a valid business email")
		break
	}
	case FieldTypes.SelectField: {
		yupField = yup.string()
		break
	}
	}

	if (required) {
		const messageText = message || "This field is required"
		yupField = yupField.required(messageText)
	}

	return yupField
}

export const generateSchema = (fields: FormFieldBase[]) => {
	const shape = fields?.reduce((acc, field) => {
		const { fieldName } = field
		acc[fieldName] = generateFieldValidation(field)

		return acc
	}, {})

	return yup.object().shape(shape)
}

export const generateDefaultValues = (fields: FormFieldBase[]) => {
	return fields?.reduce((acc, field) => {
		const { type, fieldName } = field
		switch (type) {
		case FieldTypes.SelectField: {
			acc[fieldName] = ""
			break
		}
		default:
		}
		return acc
	}, {})
}

// const IOSregex = /(iPad|iPhone|iPod touch);.*CPU.*OS 7_\d/i
// export const isIOS = () => {
// 	return navigator.userAgent.match(IOSregex)
// }

export const isIOS = () => {
	return typeof navigator !== "undefined"
		? ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].indexOf(navigator.platform) >=
				0 ||
				(navigator.userAgent.indexOf("Mac") >= 0 && "ontouchend" in document)
		: false
}

export const isAndroid = () => {
	return typeof navigator !== "undefined" ? ["Android"].indexOf(navigator.platform) >= 0 : false
}
